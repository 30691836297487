import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

interface DialogData {
id:number
}
@Component({
  selector: 'dialog-ticket',
  template: `<div mat-dialog-content>
  <p>Jobcard has been saved!</p>
<p>Click <span class="navigationLink" (click)="openJC()">here</span> to view the Jobcard.</p>


</div>
<div mat-dialog-actions>
  <button class="lht-btn cancel"  mat-button  (click)="onNoClick()" >Close</button>
</div>`
})
export class NewJCComponent {
  link: string;
    //"/jobcard/" + this.data.id + ";jobCardId=" + this.data.id;
  constructor(
    public dialogRef: MatDialogRef<NewJCComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

 
  onNoClick(): void {
    this.dialogRef.close(false);
    
   
  }
  openJC() {
    this.link = "https://lht-displayperform-dev.azurewebsites.net/jobcard/" + this.data.id + ";jobCardId=" + this.data.id
    //  
    window.open(this.link,"_blank")
  }
}
