import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogData } from '../../models';

@Component({
  selector: 'dialog-ticket',
  template: `<div mat-dialog-content>
  <p>Please select priority before submitting!</p>

 <select-component [selectedOption]="data" [options]="priority" [isMultiple]="false" (selected)="getPriority($event)"></select-component>
</div>
<div mat-dialog-actions>
  <button  class="lht-btn" mat-button [mat-dialog-close]="data.name">Yes</button>
  <button class="lht-btn cancel"  mat-button  (click)="onNoClick()" >Close</button>
</div>`
})
export class NewTicketComponent {

  constructor(
    public dialogRef: MatDialogRef<NewTicketComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  priority = [{
    id: 1,
    name: 'Low'
  }, {
      id: 2,
      name: 'Normal'
    }, {
      id: 3,
      name: 'High'
    }]
  getPriority(val) {
    this.data.name=val.name
   

  }
  onNoClick(): void {
    this.dialogRef.close(false);
  }
 
}
