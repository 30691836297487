import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { NonRoutineItemService } from '../nonRoutineItem.service';
import { Subscription, Observable } from 'rxjs';
import { FormGroup, FormControl, FormArray, Validators, FormBuilder } from '@angular/forms';
import { WorkStation, UserWorkStation, User } from '../models';
import { Router } from '@angular/router';
import { ActivatedRoute } from "@angular/router";
@Component({
  selector: 'create-user',
  templateUrl: 'create-user.component.html'

})


export class CreateUserComponent {
  constructor(private api: NonRoutineItemService, private fb: FormBuilder, private router: Router, private route: ActivatedRoute) {

    this.userData = this.fb.group({
      name: new FormControl('', Validators.required),
      email: new FormControl('', Validators.required),
      id: new FormControl(),
      userWorkStation: new FormControl('', Validators.required),
      metricSystem: new FormControl(),
      defManAdmin: new FormControl(),
    })
  }
  submitted: boolean;
  user;
  isCreate: boolean;
  workStationsArr: UserWorkStation[]
  selectedUser;
  userData: FormGroup;
  createSubsc: Subscription;
  updateSubsc: Subscription
  userSubscr: Subscription;
  show: boolean = false;
  adminRole;
  warning=null;
  userCheckSubscr: Subscription;
  stationsData$: Observable <WorkStation[]> = this.api.getWorkStation();
  metric = [{ id: 1, name: 'Millimeters ', value: true }, { id: 2, name: 'Inches', value: false }]
  selectedMetric;
  routeParamsSubsc: Subscription;
  public role = [
    { id: 'yes', name: 'Yes', value: true, radioGroup: 'roleOptions' },
    { id: 'no', name: 'No', value: false, radioGroup: 'roleOptions' },
   
  ];
  getSelectedUser(message) {

    this.selectedUser = message
   
  }
  saveInfo() {

  }
  joinArrId(arr) {
    if (arr) {
      let stations = [];
      arr.forEach(item => {
        if (item.workStation) {
          stations.push(item.workStation.id)
        } else {
          stations.push(item.workStationId)
          
        }
        
      })
    
      return stations
     
    }
  }
  getRole(message) {
    if (message.id == 'yes' && message.checked) {
      this.userData.controls['defManAdmin'].setValue(true)
    }
    if (message.id == 'no' && message.checked) {
      this.userData.controls['defManAdmin'].setValue(false)
    }
    
  }
  getMetric(message) {
    if (message.value) {
      this.userData.controls['metricSystem'].setValue(true)
    } else {
      this.userData.controls['metricSystem'].setValue(false)
    }
  }
  saveUser(data: FormGroup) {
    this.submitted = true;
    if (data.valid) {
      let dataToSend: User = data.value;
      this.updateSubsc = this.api.updateUser(dataToSend).subscribe(result => {
       
        this.router.navigate(['/admin']);
      }, err => {
          console.log(err)
       
      });
    }
   
    
   
  }
  saveMultiple(message) {
    let workStations: UserWorkStation[]=[];
    message.forEach((item,index) => {
      workStations.push({ userId: this.userData.controls['id'].value , workStationId: message[index] })
    })
    this.userData.controls['userWorkStation'].setValue(workStations)
  }


  cancelUser(e) {
    e.preventDefault();
    if (!this.isCreate) {
      this.router.navigate(['/admin']);
    } else {
      if (this.userData.controls["id"].value) {
        this.api.deleteUser(Number(this.userData.controls["id"].value)).subscribe(result => {
          this.router.navigate(['/admin']);
        });
      } else {
        this.router.navigate(['/admin']);
      }
    }
  }
  addUser(e) {
    e.preventDefault();

    this.userCheckSubscr = this.api.getExistingUser(this.selectedUser.userPrincipalName).subscribe(result => {
      if (result.length == 0) {
        this.warning = null;
        if (this.selectedUser.displayName && this.selectedUser.userPrincipalName) {
          this.createSubsc = this.api.createUser().subscribe(result => {
            if (result) {

              this.userData.controls['id'].setValue(result.id)
              this.userData.controls['name'].setValue(this.selectedUser.displayName)
              this.userData.controls['email'].setValue(this.selectedUser.userPrincipalName)
              this.show = true
            }
          })
        }
      } else {
        this.warning = "User already exists!";
      }
     
    })
    
  }


  ngOnInit() {
    this.routeParamsSubsc = this.route.params.subscribe(params => {
      if (params.id) {

        this.isCreate = false;
        this.userSubscr = this.api.getUserById(Number(params.id)).subscribe(result => {
          if (result && result[0]) {
            this.userData.patchValue(result[0])
            if (this.userData.controls['defManAdmin'].value) {
              this.adminRole="Yes"
            } else {
              this.adminRole = "No"
            }
            if (this.userData.controls['metricSystem'].value) {
              this.selectedMetric = { id: 1, name: 'Millimeters', value: true }
            } else {
              this.selectedMetric ={ id: 2, name: 'Inches', value: false }
            }
          }
         
        });
        this.show = true;
      

      } else {
        this.isCreate = true;
      }
    });
  }
  ngOnDestroy() {
    if (this.createSubsc) {
      this.createSubsc.unsubscribe();
    }
    if (this.userSubscr) {
      this.userSubscr.unsubscribe();
    }
    if (this.updateSubsc) {
      this.updateSubsc.unsubscribe();
    }
  }
}
