import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import * as models from './models'
@Injectable({ providedIn: 'root' })

export class NonRoutineItemService {
  private nriUrl = '/api/NonRoutineItems';  // URL to web api
  private nriUrlFiltered = '/api/NonRoutineItems/GetAll';  // URL to web api
  private updateStatus = '/api/NonRoutineItems/UpdateStatus';
  private rectUrl = '/api/ActionTypes/GetAll';  // URL to web api for rectification
  private stepsUrl = '/api/AdditionalSteps';  // URL to web api for steps
  private defType = '/api/DefectTypes'; // URL to web api for steps for defect types
  private part = '/api/Parts'; // URL to web api for steps for defect types
  private stations = '/api/WorkStations'; // get all stations
  private layover = '/api/Layovers'; // get active layover
  private sketches = '/api/Sketches'; // get sketch 
  private defects = '/api/Defects'; // get defect
  private ataChapters = '/api/AtaChapters' //get ata chapter
  private sectionsByChapters = '/api/AtaChapters/GetAll' //get section by ata chapter
  private refType = '/api/ReferenceTypes' //get ref type for rectification
  private users = '/api/Users' //get users
  private currUser = '/api/Users/GetByEmail' //get users
  private tickets = '/api/NonRoutineItems/CreateTicketFromNonRoutineItem'; //create ticket
  private amtoss = '/api/AmtossCodes/GetAMTOSS';
  private rectActions = '/api/RectificationActions';
  private zones = '/api/Zones/GetAll';
  private aircraft = '/api/Aircraft';
  private edges = '/api/Edges';
  private description = '/api/NonRoutineItems/UpdateText';
  private eventSearch = '/api/Events/GetEvent';
  private aircraftSearch = '/api/Aircraft/GetAircraft/';
  private event = '/api/Events'
  private excel = '/api/Events/GenerateExcel';
  private azureUsers = "api/AzureUser";
  private existingUser = "/api/Users/UserExists";
  private createJCUrl = "/api/AdditionalSteps/CreateNonRoutineJC"
  private createRectJCUrl ="api/RectificationActions/CreateRectificationJC"
  httpHeaders = new HttpHeaders({
    'Content-Type': 'application/json'
  });
  headerOptions = { headers: this.httpHeaders }
  constructor(private http: HttpClient) { }

  //generate excel
  getExcelData(eventId: number) {
    const url = `${this.excel}/${eventId}`;
    return this.http.get(url, this.headerOptions);
  }
  //get users for owneer
  getUser(): Observable<models.User[]> {
    return this.http.get<models.User[]>(this.users, this.headerOptions);
  }
  getUserById(id: number): Observable<models.User[]> {
    const url = `${this.users}/${id}`;
    return this.http.get<models.User[]>(url, this.headerOptions);
  }
  getEvent(searchParam: string): Observable<models.EventModel[]> {
    const url = `${this.eventSearch}/${searchParam}`;
    return this.http.get<models.EventModel[]>(url, this.headerOptions);
  }
  getAllEvents(): Observable<models.EventModel[]> {
    return this.http.get<models.EventModel[]>(this.event, this.headerOptions);
  }
  getEventById(id: number): Observable<models.EventModel[]> {
    const url = `${this.event}/${id}`;
    return this.http.get<models.EventModel[]>(url, this.headerOptions);
  }
  getAircraft(searchParam: string): Observable<models.Aircraft[]> {
    const url = `${this.aircraftSearch}/${searchParam}`;
    return this.http.get<models.Aircraft[]>(url, this.headerOptions);
  }
  getAzureUzers(searchParam: string): Observable<models.User[]> {
    const url = `${this.azureUsers}/${searchParam}`;
    return this.http.get<models.User[]>(url, this.headerOptions);
  }
  getEdges(): Observable<models.Edge[]> {
    return this.http.get<models.Edge[]>(this.edges, this.headerOptions);
  }
  getAMTOSS(searchParam: string): Observable<models.AmtossCode[]> {
    const url = `${this.amtoss}/${searchParam}`;
    return this.http.get<models.AmtossCode[]>(url, this.headerOptions);
  }
  getCurrentUser(email: string): Observable<models.User[]> {
    const url = `${this.currUser}/${email}`;
    return this.http.get<models.User[]>(url, this.headerOptions);

  }
  getExistingUser(email: string): Observable<models.User[]> {
    const url = `${this.existingUser}/${email}`;
    return this.http.get<models.User[]>(url, this.headerOptions);
  }
  //get non routine item short info for home page list
  getShortNonRoutineItemFiltered(userId: number): Observable<models.NonRoutineItemShort[]> {
    const url = `${this.nriUrlFiltered}/${userId}`;
    return this.http.get<models.NonRoutineItemShort[]>(url, this.headerOptions);
  }
  //get non routine item short info for home page list
  getShortNonRoutineItem(): Observable<models.NonRoutineItemShort[]> {
    return this.http.get<models.NonRoutineItemShort[]>(this.nriUrl, this.headerOptions);
  }
   //get non routine item by id
  getNonRoutineItemById(id: number): Observable<models.NonRoutineItemOverview[]> {
    const url = `${this.nriUrl}/${id}`;
    return this.http.get<models.NonRoutineItemOverview[]>(url, this.headerOptions);
  }
   //get rectification actions for dropdown
  getRectificationActionsTypes(searchParam:string): Observable<models.ActionType[]> {
    const url = `${this.rectUrl}/${searchParam}`;
    return this.http.get<models.ActionType[]>(url, this.headerOptions);
  }
 
  //get defect types
  getDefType(): Observable<models.DefectType[]> {
    
    return this.http.get<models.DefectType[]>(this.defType, this.headerOptions);
    
  }
  //get defect type by id
  getDefTypeById(id: number): Observable<models.DefectType> {
    const url = `${this.defType}/${id}`;
    return this.http.get<models.DefectType>(url, this.headerOptions);

  }
  //get all parts
  getAllParts(): Observable<models.Parts[]> {
    return this.http.get<models.Parts[]>(this.part, this.headerOptions);
  }
  //get parts by search phrase
  getPart(searchParam:string): Observable<models.Parts[]> {
    const url = `${this.part}/${searchParam}`;
    return this.http.get<models.Parts[]>(url, this.headerOptions);
  }

  //get workstations
  getWorkStation(): Observable<models.WorkStation[]> {
   
    return this.http.get<models.WorkStation[]>(this.stations, this.headerOptions);
  }

  //get layovers
  //getLayovers(): Observable<models.Layover[]> {

  //  return this.http.get<models.Layover[]>(this.layover, this.headerOptions);
  //}
  //get sketches
  getSketch(): Observable<models.Sketch[]> {
    return this.http.get<models.Sketch[]>(this.sketches, this.headerOptions);
  }
  //get defect by nriID
  getDefect(id: number): Observable<models.Defect[]> {
    const url = `${this.defects}/${id}`;
    return this.http.get<models.Defect[]>(url, this.headerOptions);
  }
  //get sections by chapter
  getAtaSection(chapter): Observable<models.AtaChapter[]> {
    const url = `${this.sectionsByChapters}/${chapter}`;
    return this.http.get<models.AtaChapter[]>(url, this.headerOptions);
  }
  getAtaObject(chapter,section): Observable<models.AtaChapter[]> {
    const url = `${this.ataChapters}/${chapter}/${section}`;
    return this.http.get<models.AtaChapter[]>(url, this.headerOptions);
  }
  getSketchByAtaChapter(chapter: string): Observable<models.AtaChapter[]> {
    const url = `${this.ataChapters}/${chapter}`;
    return this.http.get<models.AtaChapter[]>(url, this.headerOptions);
  }
  getAtaBySketch(id: number): Observable<models.AtaChapter[]> {
    const url = `${this.ataChapters}/${id}`;
    return this.http.get<models.AtaChapter[]>(url, this.headerOptions);
  }
  getRefType() {
    return this.http.get<models.ReferenceType[]>(this.refType, this.headerOptions);
  }
  getRectifications(nriId: number): Observable<models.RectificationAction[]>{
    const url = `${this.rectActions}/${nriId}`;
    return this.http.get<models.RectificationAction[]>(url, this.headerOptions);
  };

  getSteps(nriId: number): Observable<models.AdditionalStep[]> {
    const url = `${this.stepsUrl}/${nriId}`;
    return this.http.get<models.AdditionalStep[]>(url, this.headerOptions);
  }
  getZone(searchParam: string): Observable<models.Zone[]> {
    const url = `${this.zones}/${searchParam}`;
    return this.http.get<models.Zone[]>(url, this.headerOptions);
  }

  createEvent(event: models.EventModel) {
    return this.http.post<models.EventModel>(this.event, event, this.headerOptions);
  }
  updateEvent(event: models.EventModel) {
    return this.http.patch<models.EventModel>(this.event, event, this.headerOptions);
  }
  deleteEvent(id: number) {
    const url = `${this.event}/${id}`;
    return this.http.delete(url, this.headerOptions);

  }
  saveItem(formData: models.NonRoutineItem) {
 
    return this.http.post<models.NonRoutineItem>(this.nriUrl, formData, this.headerOptions);
  }
  updateNRIStatus(id:number,status:string) {
    const nriForUpdate: models.NonRoutineItem = {
      id: id,
      Status: status
    }
    return this.http.patch<models.NonRoutineItem>(this.updateStatus, nriForUpdate, this.headerOptions)
   
  }
 
  updateItem(formData) {
  
    return this.http.patch<models.NonRoutineItem>(this.nriUrl, formData, this.headerOptions);
  }
  createDefect(defect:models.Defect) {

    return this.http.post<models.Defect>(this.defects, defect, this.headerOptions);
  }
  updateDefects(updatedObj: models.Defect[],userId) {
    const url = `${this.defects}/${userId}`;
    return this.http.patch<models.Defect[]>(url, updatedObj, this.headerOptions);
  }

  deleteDefect(id:number) {
    const url = `${this.defects}/${id}`;
    return this.http.delete(url, this.headerOptions);
  }
  deleteStep(id: number) {
    const url = `${this.stepsUrl}/${id}`;
    return this.http.delete(url, this.headerOptions);
  }
  deleteRectification(id: number) {
    const url = `${this.rectActions}/${id}`;
    return this.http.delete(url, this.headerOptions);
  }
  updateUserMetric(userId: number, metric: boolean) {
    const url = `${this.users}/${userId}/${metric}`;
    return this.http.patch(url, this.headerOptions);
  }
  createTicket(ticket: models.Ticket) {
    return this.http.post<models.Ticket>(this.tickets, ticket, this.headerOptions);
  
  }
  createUser() {
    let newUser: models.User = {}
    return this.http.post<models.User>(this.users, newUser, this.headerOptions);
  }
  deleteUser(id: number) {
    const url = `${this.users}/${id}`;
    return this.http.delete(url, this.headerOptions);
  }
  updateUser(user: models.User) {
    const url = `${this.users}/${user.id}`;
    return this.http.patch<models.User>(url, user, this.headerOptions);
  }
  createRectificationAction(rectification: models.RectificationAction) {
    return this.http.post<models.RectificationAction>(this.rectActions, rectification, this.headerOptions)
    
  }


  updateRectificationAction(rectifications: models.RectificationAction[],userID: number) {
   
    const url = `${this.rectActions}/${userID}`;
    return this.http.patch<models.RectificationAction>(url, rectifications, this.headerOptions)
   
  }
  createStep(step: models.AdditionalStep) {
    return this.http.post<models.AdditionalStep>(this.stepsUrl, step, this.headerOptions)
  }
  updateSteps(steps: models.AdditionalStep[], userID: number) {
    const url = `${this.stepsUrl}/${userID}`;
    return this.http.patch<models.AdditionalStep>(url, steps, this.headerOptions)
  }
  updateNRIDescription(nriId: number, description: string) {

    const nriForUpdate: models.NonRoutineItem = {
      id: nriId,
      DescriptionText: description
      
    }
    return this.http.patch<models.NonRoutineItem>(this.description, nriForUpdate, this.headerOptions)


  }

  createJC(nriId: number) {
    //createJCUrl
    const url = `${this.createJCUrl}/${nriId}`;
    return this.http.post(url, this.headerOptions)
  }
  createRectJC(nriId: number,htmlTemplate:string) {
    //createJCUrl
    let objToSend = {
      "DefectInfo": htmlTemplate
    } 
    const url = `${this.createRectJCUrl}/${nriId}`;
    return this.http.post(url, objToSend, this.headerOptions)
  }
}


