import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
//import { AdalGuard } from 'adal-angular4';
import {CanDeactivateGuard } from '../app/auth-guard.service';
import { HomeContainer } from '../app/home/home.container';
import { TabGroup } from '../app/nav-menu/tabs/tab-group.component';
import { LoginComponent } from './login/login.component';
import { LogoutComponent } from './logout/logout';
import { AuthCallbackComponent } from '../app/authcallback/authcallback';
import { AuthGuardService as AdalGuard } from '../app/auth-guard.service';
import { UserPreferencesComponent} from '../app/preferences/user-preferences';
import { LayoverManagementComponent } from '../app/admin/layover-management.component';
import { LayoverFormComponent } from '../app/admin/layover-form.component';
import { AdminPanelComponent } from '../app/admin/admin-panel.component';
import {CreateUserComponent } from '../app/admin/create-user.component';
const routes: Routes = [
  {path: '',redirectTo: 'home', pathMatch: 'full',},
  { path: 'login', component: LoginComponent },

  { path: 'logout', component: AuthCallbackComponent },
  { path: 'settings', component: UserPreferencesComponent, canActivate: [AdalGuard] },
 
  { path: 'home', component: HomeContainer, canActivate: [AdalGuard] },
  { path: 'create', component: TabGroup, canActivate: [AdalGuard], canDeactivate: [CanDeactivateGuard] },
  { path: 'view/:id', component: TabGroup, canActivate: [AdalGuard] },
  { path: 'auth-callback', component: AuthCallbackComponent },
  { path: 'admin', component: AdminPanelComponent, canActivate: [AdalGuard] },
  { path: 'layover-management', component: LayoverManagementComponent, canActivate: [AdalGuard] },
  { path: 'layover-management/layover-form', component: LayoverFormComponent, canActivate: [AdalGuard] },
  { path: 'layover-management/layover-form/:id', component: LayoverFormComponent, canActivate: [AdalGuard] },
  { path: 'admin/user-form', component: CreateUserComponent, canActivate: [AdalGuard] },
  { path: 'admin/user-form/:id', component: CreateUserComponent, canActivate: [AdalGuard] }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [CanDeactivateGuard]
})
export class AppRoutingModule { }

