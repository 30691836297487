import { Component,Input } from '@angular/core';



@Component({
  selector: 'app-spinner',
  template: '<div *ngIf="show" style="position: fixed;left: 0;right: 0"> <div class="sp sp-circle"></div><div style="text-align:center">Loading...</div></div>'
 
})
export class SpinnerComponent {
  @Input() show;

  ngOnInit() {

  }


}
