import { Component, Input, Output, EventEmitter } from '@angular/core';

/**
 * @title Radios with ngModel
 */
@Component({
  selector: 'radio-ng-model',
  templateUrl: 'radio-ng-model.html'
})
export class RadioNgModelExample {
  optionRadio: string;
  @Input() incomingOptions: object[];
  @Output() radioVal = new EventEmitter<object>();
  @Input() selectedValue;
  @Input() name;
  constructor() { }
  ngOnInit() {
   
  }

  selectOption(val) {
  
    this.radioVal.emit(val);
  }
}
