import { Component, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import {FormsService } from '../forms.service';
import { FormGroup, NgForm, FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { DialogDeleteComponent } from '../common/dialogs/dialogs.component';
import {NonRoutineItemService } from '../nonRoutineItem.service';
@Component({
  selector: 'app-rectification',
  templateUrl: './rectification.component.html',

})
export class RectificationComponent {
  private _description
  @Input() rectificationsListArray;
  @Input() rectificationFields;
  @Input() selectOptionsRef;
  @Input() formData: FormGroup;
  @Input() selectedRefType;
  @Input() selectedRefAction;
  @Input() stations;
  @Input() fail;
  @Input() hasChanges: boolean;

  @Input() disableFormFields

 


  //descriptionText
  @Input() set descriptionText(val: string) {
    this._description = val;
    this.descriptionControl.setValue(this._description)

  }
  get descriptionText(): string {
    return this._description
  }
  allItems;
  filtered;
  @Output() submitTicket: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() onSubmit: EventEmitter<any> = new EventEmitter<any>();
  @Output() createRectJC: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() createRectification: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() selectedVal: EventEmitter<any> = new EventEmitter<any>();
  descriptionControl = new FormControl();

  @ViewChild('f', { static: false }) public form: NgForm;
  public whatOptions = [
    { name: 'Component', value: 1, id: 1 },
    { name: 'Panel', value: 2, id: 2 },
    { name: 'Zone', value: 3, id: 3 },
    { name: 'Wiring', value: 4, id: 4 }

  ];
  constructor(private fservice: FormsService, private service: NonRoutineItemService, public dialog: MatDialog) { }
  chosenVal(message, fgroup: FormGroup) {

    fgroup.controls['part'].setValue(null)
    fgroup.controls['partId'].setValue(null)
    fgroup.controls['panel'].setValue(null)
    fgroup.controls['wiring'].setValue(null)
    fgroup.controls['zone'].setValue(null)
    fgroup.controls['zoneId'].setValue(null)
    fgroup.controls['quantity'].setValue(null)
    this.selectedVal.emit({ what: message, fg: fgroup })
  }
  selectAction(result, fgroup: FormGroup) {
    fgroup.controls['actionTypeId'].markAsDirty();
    fgroup.controls['actionTypeId'].setValue(result.id)
    fgroup.controls['actionType'].setValue(result)
    this.markId(fgroup);

  }
  rectType(result, fgroup: FormGroup) {
    fgroup.controls['referenceTypeId'].markAsDirty();
    fgroup.controls['referenceTypeId'].setValue(result.id)
    fgroup.controls['referenceType'].setValue(result)
    if (result.name === 'IPC') {
      fgroup.controls['item'].setValue('')
    } else {
      fgroup.controls['item'].setValue(null)
    }
    this.markId(fgroup);

  }
  selectWC(val, fgroup: FormGroup) {
    fgroup.controls['workStationId'].markAsDirty();
    fgroup.controls['workStationId'].setValue(val.id)
    fgroup.controls['workStation'].setValue(val)
    this.markId(fgroup);

  }
  partChosen(message, fgroup: FormGroup) {
    fgroup.controls['partId'].markAsDirty();
    fgroup.controls['partId'].setValue(message.id)
    fgroup.controls['part'].setValue(message)
    fgroup.controls['quantity'].setValue(1)
    this.markId(fgroup);


  }

  selectZone(val, fgroup: FormGroup) {
    fgroup.controls['zoneId'].markAsDirty();
    fgroup.controls['zoneId'].setValue(Number(val.id))
    fgroup.controls['zone'].setValue(val)
    this.markId(fgroup);


  }
  openDialog(e, i, id) {
    e.preventDefault();
    const dialogRef = this.dialog.open(DialogDeleteComponent, {
      width: '350px',

    });


    dialogRef.afterClosed().subscribe(result => {
      if (result) {

        this.rectificationsListArray.removeAt(i)
        this.service.deleteRectification(Number(id)).subscribe();
        this.rectificationsListArray.controls.forEach((item, index) => {
          this.hasChanges = false;
          let currControl = <FormGroup>item
          if (!currControl.controls['taskId'].value) {
            this.hasChanges = true;
          }
        });
      }
    });
  }

  getQuantity(val, fgroup: FormGroup) {
    fgroup.controls['quantity'].markAsDirty();
    fgroup.controls['quantity'].setValue(val)
    this.markId(fgroup);
  }

  markId(fgroup: FormGroup) {
    fgroup.controls['id'].markAsDirty();
  }
  addRectification(e) {
    e.preventDefault();
    this.createRectification.emit(true)
    this.hasChanges = true;
  }
  createJC(e) {
    e.preventDefault();
    this.createRectJC.emit(true)

  }


  ngOnInit() {

    
  }


  ngOnDestroy() {
   
   
  }
}
