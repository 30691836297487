import { Component, OnInit, Input, EventEmitter, Output, ViewChild} from '@angular/core';
import { FormGroup, NgForm } from '@angular/forms';
import { NonRoutineItemOverview, WorkStation, EventModel } from '../models';
import { ActivatedRoute } from "@angular/router";
import { Subscription, Observable } from 'rxjs';
import {NonRoutineItemService } from '../nonRoutineItem.service';
@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html'
})
export class OverviewComponent implements OnInit {

  @Input() passedData: FormGroup;
  @Input() formData: FormGroup;
  @Input() isCreate: boolean;
  @Input() isForEdit: boolean;
  @Input() itemReceived: NonRoutineItemOverview[] = [];
  _stations;
  //@Input() stations: WorkStation[];
  @Input() set stations(val: WorkStation[]) {
    this._stations = val;
    if (val && this.wsId) {
      let selectedWS = val.filter(s =>
        s.id == Number(this.wsId)
      )
      this.stationFromDP = selectedWS[0];
      this.formData.controls['LeadingWorkStationId'].markAsDirty();
      this.formData.controls['LeadingWorkStationId'].setValue(Number(this.wsId));
    }
  }
  get stations(): WorkStation[] {
    return this._stations
  }
  @Input() selectedOwner;
  @Input() currUser;
  @Input() users;
  @Input() showSpinner: boolean;
  @Output() onSubmit: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();
  @Output() onEdit: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() onSaveChanges: EventEmitter<any> = new EventEmitter<any>();
  @Output() saveMultipleVals: EventEmitter<any> = new EventEmitter<any>();
  @Output() leadingWC: EventEmitter<any> = new EventEmitter<any>();
  @Output() owner: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('f', { static: false }) public form: NgForm;
  @ViewChild('edit', { static: false }) public editForm: NgForm;
  @Input() submitted: boolean;
  eventId;
  stationFromDP;
  ojc;
  owp;
  event;
  jcId;
  wsId;
  routeParamsSubscr: Subscription;
  eventSuscr: Subscription;
  constructor(private route: ActivatedRoute, private api: NonRoutineItemService) { }

  returnToFixed(number) {
    if (number) {
      return Number(number).toFixed(2);
    }
   
  }
  saveSingle(message) {
    this.leadingWC.emit(message.id)
  }
  saveChanges() {
    this.formData.controls['ModifiedBy'].markAsDirty();
    this.formData.controls['ModifiedBy'].setValue(this.currUser.name);

    this.onSaveChanges.emit(this.formData)
  }
  joinArr(arr: any[]) {
    return arr.map(o => o.name);
  }
 format_two_digits(n) {
  return n < 10 ? '0' + n : n;
}

  joinArrId(arr: any[]) {
 
    return arr.map(o => o.id);
  }

  getDate(dateString: string) {
  
    let date = new Date(dateString);
    let day = this.format_two_digits(date.getDate());
    let month = this.format_two_digits(date.getMonth()+1);
    let year = date.getFullYear();
    let hours = date.getHours();
    let minutes = date.getMinutes();
   
    let transformedDate = day + '.' + month + '.' + year + ' ' + this.format_two_digits(hours) + ':' + this.format_two_digits(minutes)
   
    return transformedDate;
  }

  getDateWithoutH(dateString: string) {

    let date = new Date(dateString);
    let day = this.format_two_digits(date.getDate());
    let month = this.format_two_digits(date.getMonth() + 1);
    let year = date.getFullYear();
  

    let transformedDate = day + '.' + month + '.' + year

    return transformedDate;
  }
  emptyField(message) {
    if (message) {
      this.formData.controls['OwnerId'].reset();
    }
  }
  editOverview(event) {    
    event.preventDefault();
    this.onEdit.emit(!this.isForEdit)
  }
  saveMultiple(values) {
  
    this.saveMultipleVals.emit(values)
  }

  getOwner(owner) {
    this.owner.emit(owner)
  }
  getLayover(message) {
 
    this.formData.controls['EventId'].markAsDirty();
    this.formData.controls['EventId'].setValue(message.id);
    this.formData.controls['OriginatingJobCard'].markAsDirty();
    this.formData.controls['OriginatingJobCard'].setValue(message.originatingServiceOrder);
   
 
  }


  ngOnInit() {
    this.routeParamsSubscr = this.route.paramMap.subscribe(params => {
      if (params.has('owp') && params.has('eventId') && params.has('ojc') && params.has('jcId') && params.has('wsId') ) {
        this.eventId = this.route.snapshot.params.eventId
        this.ojc = this.route.snapshot.params.ojc
        this.owp = this.route.snapshot.params.owp
        this.jcId = this.route.snapshot.params.jcId
        this.wsId = this.route.snapshot.params.wsId
        this.eventSuscr = this.api.getEventById(Number(this.eventId)).subscribe(result => {
          if (result && result[0]) {
            this.event = result[0]
          }
         
        });
        this.formData.controls['OriginatingWorkPack'].markAsDirty();
        this.formData.controls['OriginatingJobCard'].markAsDirty();
        this.formData.controls['EventId'].markAsDirty();

        this.formData.controls['JobCardId'].markAsDirty();
        this.formData.controls['JobCardId'].setValue(Number(this.jcId));
        this.formData.controls['OriginatingWorkPack'].setValue(this.owp);
        this.formData.controls['OriginatingJobCard'].setValue(this.ojc);
        this.formData.controls['EventId'].setValue(Number(this.eventId));
      
      }
    });

  }
  ngOnDestroy() {
    if (this.routeParamsSubscr) {
      this.routeParamsSubscr.unsubscribe();
    }
    if (this.eventSuscr) {
      this.eventSuscr.unsubscribe();
    }
  }

}
