import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { AdalService } from 'adal-angular4';
import { BehaviorSubject, Observable } from 'rxjs';
import { CanDeactivate } from '@angular/router';

@Injectable()
export class AuthGuardService implements CanActivate {
  private user = new BehaviorSubject<string>('');
  cast = this.user.asObservable();

  updateUser(loggedUser) {
    this.user.next(loggedUser)
  }

  constructor(public auth: AdalService, public router: Router) {}
  private originalUrl = window.localStorage;
  canActivate(): boolean {
        
 

    if (!this.auth.userInfo.authenticated) {

      this.originalUrl.setItem('originalUrl', window.location.href);
      this.router.navigate(['auth-callback']);
   
      return false;
    }
    this.updateUser(this.auth.userInfo)
    return true;
  }

}

export interface CanComponentDeactivate {
  canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
}

@Injectable({
  providedIn: 'root',
})

export class CanDeactivateGuard implements
  CanDeactivate<CanComponentDeactivate> {

  canDeactivate(component: CanComponentDeactivate) {
    return component.canDeactivate ? component.canDeactivate() : true;
  }
}
