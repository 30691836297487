import { Component, ComponentFactoryResolver, Input, Inject, EventEmitter, Output,ViewContainerRef, ViewChild, OnInit, ComponentRef, OnDestroy} from '@angular/core';
import { StepComponent } from './steps.component';
import { FormsService } from '../forms.service';
import { NonRoutineItemService } from '../nonRoutineItem.service';
import { AdditionalStep, Parts, WorkStation, ReferenceType, Notification } from '../models';
import { Subscription, Observable } from 'rxjs';
import { FormGroup, NgForm, FormBuilder, FormArray, FormControl } from '@angular/forms';
import { WhatWherePresenter } from '../what-where-2/what-where.presenter';
import { NewJCComponent } from '../common/dialogs/newJC.component';
import { MatDialog } from '@angular/material/dialog';
import { DialogDeleteComponent } from '../common/dialogs/dialogs.component';
@Component({
  selector: 'steps-container',
  templateUrl: 'steps.container.html',
  providers: [WhatWherePresenter]


})

export class StepContainer {
  @Output() isSuccess: EventEmitter<Notification> = new EventEmitter<Notification>(); 
  @ViewChild(StepComponent, { static: false }) children: StepComponent;
  stationsData$: Observable<WorkStation[]> = this.service.getWorkStation();
  refType$: Observable<ReferenceType[]> = this.service.getRefType();
  formData: FormGroup;
  nriIdObjSubscr: Subscription;
  userSubscr: Subscription;
  stepsSubscr: Subscription;
  jcSubscr: Subscription;
  nriId;
  userId;
  fail: boolean = false;
  steps;
  stepsToSend;
  hasChanges: boolean;
  @Input() disableFormFields;
  constructor(
    public dialog: MatDialog,
    private service: NonRoutineItemService,
    private fservice: FormsService,
    private fb: FormBuilder,
    private presenter: WhatWherePresenter) {
    this.formData = this.fb.group({

      steps: this.fb.array([])
    })
  }
  openJCDialog(jcId: number) {

    const dialogRef = this.dialog.open(NewJCComponent, {
      width: '350px',
      data: { id: jcId }

    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {



      }
    });
  }
  createStep(message) {

    if (message) {
      const dataToSend: AdditionalStep = {
        nonRoutineItemId: this.nriId
      }
      this.service.createStep(dataToSend).subscribe(result => {
        this.stepsListArray.push(this.stepFields())
        let control = <FormGroup>this.stepsListArray.controls[this.stepsListArray.controls.length - 1]
        control.controls['id'].markAsDirty();
        control.controls['id'].setValue(result.id);
        control.controls['nonRoutineItemId'].markAsDirty();
        control.controls['nonRoutineItemId'].setValue(this.nriId);
        this.hasChanges = true;
       
      }, err => {
        this.isSuccess.emit({ message: 'Something whent wrong,could not create step!Please try again!', isSuccess: false })
      });
    }
   
  }
  updateSteps(message) {
    this.stepsToSend = [];
    this.stepsListArray.controls.forEach(item => {

      let fgItem = <FormGroup>item;

      // let data: RectificationAction = this.fservice.submitChangedData(fgItem)
      if (!this.presenter.isEmpty(fgItem) && fgItem.dirty) {
        this.stepsToSend.push(<AdditionalStep>fgItem.value)
      }

    })
   
    if (this.stepsToSend.length !== 0 && this.formData.dirty) {
     
      this.service.updateSteps(this.stepsToSend, this.userId).subscribe(result => {
        this.formData.markAsPristine();
      }, err => {
        this.isSuccess.emit({ message: 'Something whent wrong,could not update steps!Please try again!', isSuccess: false })
      });
    }
  }
  get stepsListArray() {
    return <FormArray>this.formData.get('steps')
  }
  createJC(e) {
     if (this.formData.dirty) {
       this.children.form.ngSubmit.emit(this.formData);
      this.jcSubscr = this.service.createJC(this.stepsListArray.value[0].nonRoutineItemId).subscribe(result => {
        this.fail = false;
        this.updateStepsData();
        this.openJCDialog(Number(result))

      }, err => {
          this.fail = true;
          setTimeout(() => {
            this.fail = false;

          }, 3000);
        });


    } else {
      this.jcSubscr = this.service.createJC(this.stepsListArray.value[0].nonRoutineItemId).subscribe(result => {
        this.fail = false;
        this.updateStepsData();
        this.openJCDialog(Number(result))
      }, err => {
          this.fail = true;
          setTimeout(() => {
            this.fail = false;

          }, 3000);
      });

    }
   
  }
  stepFields() {
    return this.fb.group({
      id: new FormControl(),
      actionType: new FormControl(),
      actionTypeId: new FormControl(),
      order: new FormControl(),
      nonRoutineItemId: new FormControl(),
      part: new FormControl(),
      partId: new FormControl(),
      panel: new FormControl(),
      wiring: new FormControl(),
      zone: new FormControl(),
      zoneId: new FormControl(),
      workStation: new FormControl(),
      workStationId: new FormControl(),
      referenceType: new FormControl(),
      referenceTypeId: new FormControl(),
      referenceDocRevision: new FormControl(),
      referenceDocNumber: new FormControl(),
      quantity: new FormControl(),
      selectedRadio: new FormControl(),
      item: new FormControl(),
      duplicateInspection: new FormControl(),
      taskStatus: new FormControl(),
      taskId: new FormControl(),
      disableCurr: new FormControl(),
      
     
    })
  }
  chosenVal(message) {
    if (message.what.id == 1 && message.what.checked==true ) {
      message.fg.controls['part'].setValue('')
      message.fg.controls['zone'].setValue(null)
      message.fg.controls['wiring'].setValue(null)
      message.fg.controls['panel'].setValue(null)

    } else {
      message.fg.controls['part'].setValue(null)
    }

    if (message.what.id == 2 && message.what.checked == true) {
      message.fg.controls['zone'].setValue(null)
      message.fg.controls['wiring'].setValue(null)
      message.fg.controls['panel'].setValue('')
     

    } 
    if (message.what.id == 3 && message.what.checked == true) {
      message.fg.controls['zone'].setValue('')
      message.fg.controls['wiring'].setValue(null)
      message.fg.controls['panel'].setValue(null)
      

    }
    if (message.what.id == 4 && message.what.checked == true) {
      message.fg.controls['zone'].setValue(null)
      message.fg.controls['wiring'].setValue('')
      message.fg.controls['panel'].setValue(null)

    }
    if (message.what.checked == false) {
      message.fg.controls['zone'].setValue(null)
      message.fg.controls['wiring'].setValue(null)
      message.fg.controls['panel'].setValue(null)
    }
  }
  openDialog(data) {
   
    
    const dialogRef = this.dialog.open(DialogDeleteComponent, {
      width: '350px',

    });


    dialogRef.afterClosed().subscribe(result => {
      if (result) {

        this.stepsListArray.removeAt(data.index)
        this.service.deleteStep(Number(data.id)).subscribe();
        this.stepsListArray.controls.forEach((item, index) => {
          this.hasChanges = false;
          let currControl = <FormGroup>item
          if (!currControl.controls['taskId'].value) {
            this.hasChanges = true;
          }
        });
      }
    });
  }
  updateStepsData() {
    this.stepsListArray.clear();
    this.stepsSubscr = this.service.getSteps(this.nriId).subscribe(result => {
      
      if (result && result.length > 0) {
        this.steps = result;
      
        for (let i = 0; i < this.steps.length; i++) {
          this.stepsListArray.push(this.stepFields())

          this.stepsListArray.controls[i].patchValue(this.steps[i])

        }
        this.hasChanges = false
        this.stepsListArray.controls.forEach((item, index) => {
          let currControl = <FormGroup>item
          if (currControl.controls['actionType'].value) {
            currControl.controls['actionTypeId'].setValue(currControl.controls['actionType'].value.id)
          }
          if (currControl.controls['referenceType'].value) {
            currControl.controls['referenceTypeId'].setValue(currControl.controls['referenceType'].value.id)
          }
          if (currControl.controls['workStation'].value) {
            currControl.controls['workStationId'].setValue(currControl.controls['workStation'].value.id)
          }
          if (currControl.controls['part'].value) {
            currControl.controls['selectedRadio'].setValue('Component')
            currControl.controls['partId'].setValue(currControl.controls['part'].value.id)
          }
          if (currControl.controls['zone'].value) {
            currControl.controls['selectedRadio'].setValue('Zone')
            currControl.controls['zoneId'].setValue(currControl.controls['zone'].value.id)
          }

          if (currControl.controls['panel'].value) {
            currControl.controls['selectedRadio'].setValue('Panel')

          }
          if (currControl.controls['wiring'].value) {
            currControl.controls['selectedRadio'].setValue('Wiring')
          }
          if (currControl.controls['taskId'].value) {
            currControl.controls['disableCurr'].setValue(true)
          } else {
            this.hasChanges=true
          }

        })

      }
     
    })
   
  }

  ngOnInit() {
    this.nriIdObjSubscr = this.fservice.castNriObject.subscribe(result => {
      if (result) {
        this.nriId = Number(result[0].id)
        this.updateStepsData();
      }
    });

    this.userSubscr = this.fservice.castCurrentUser.subscribe(result => {
      if (result) {
        this.userId = Number(result[0].id)
      }
    });
  
  }
  ngOnDestroy() {
    if (this.stepsSubscr) {
      this.stepsSubscr.unsubscribe();
    }
    this.nriIdObjSubscr.unsubscribe();
    this.userSubscr.unsubscribe();
    if (this.jcSubscr) {
      this.jcSubscr.unsubscribe()
    }
  }
}


