import { Component, Input, EventEmitter, Output, ViewChild } from '@angular/core';
import { NonRoutineItemShort } from '../models';

@Component({
  selector: 'app-home-component',
  templateUrl: 'home.component.html'
})
export class HomeComponent {

  @Input() showSpinner
  selectedValue='';

  @Input() items: NonRoutineItemShort[];
  @Output() filterNRI: EventEmitter<any> = new EventEmitter<any>();

  public filterOptions = [{ name: 'My Station', id: 1, value: 1 },
    { name: 'My Items', id: 2, value: 2 },
    { name: 'All items', id: 3, value: 3 }
  ];
  defaultSelected =3


  filter(message: any) {
    
    this.filterNRI.emit(message.id)

    
  }
 
}





