import { FormGroup, NgForm, FormArray, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from "@angular/router";
import { Subscription } from 'rxjs';
import { NonRoutineItemService } from '../nonRoutineItem.service';
import { FormsService} from '../forms.service';
import { EventModel } from '../../app/models';
import { NativeDateAdapter } from '@angular/material';
import { MatDateFormats } from '@angular/material/core';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';


export class AppDateAdapter extends NativeDateAdapter {

  format(date: Date, displayFormat: Object): string {
    if (displayFormat === 'input') {
      let day: string = date.getDate().toString();
      day = +day < 10 ? '0' + day : day;
      let month: string = (date.getMonth() + 1).toString();
      month = +month < 10 ? '0' + month : month;
      let year = date.getFullYear();
      return `${day}.${month}.${year}`;
    }
    return date.toDateString();
  }
}
export const APP_DATE_FORMATS: MatDateFormats = {
  parse: {
    dateInput: { month: 'short', year: 'numeric', day: 'numeric' },
  },
  display: {
    dateInput: 'input',
    monthYearLabel: { year: 'numeric', month: 'numeric' },
    dateA11yLabel: {
      year: 'numeric', month: 'long', day: 'numeric'
    },
    monthYearA11yLabel: { year: 'numeric', month: 'long' },
  }
};

@Component({
  selector: 'layover-form',
  templateUrl: 'layover-form.component.html',
  providers: [
    { provide: DateAdapter, useClass: AppDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS }
  ]
})

export class LayoverFormComponent {

  layoverData: FormGroup;
  @ViewChild('f', { static: false }) public form: NgForm;
  id;
  alertMessage: string;
  model;
  isSuccess: boolean;
  showNotification: boolean = false;
  isCreate: boolean;
  submitted: boolean = false;
  routeParamsSubsc: Subscription;
  eventSubscr: Subscription;
  tatSubscr: Subscription;
  startSubscr: Subscription;
  customerWPIssueDate;
  layoverStartDate;
  layoverEndDate;
  tat;


  constructor(private fb: FormBuilder, private router: Router, private route: ActivatedRoute, private api: NonRoutineItemService, private fservice: FormsService) {
    this.layoverData = this.fb.group({
      id: new FormControl(),
      aircraft: new FormControl(),
      aircraftId:  new FormControl('', Validators.required),
      aipcRevision: new FormControl(),
      ammRevision: new FormControl(),
      bay: new FormControl(),
      customer: new FormControl(),
      customerWP: new FormControl(),
      customerWPIssueDate: new FormControl(),
      espmRevision: new FormControl(),
      layoverStartDate:  new FormControl('', Validators.required),
      layoverTAT: new FormControl(),
      layoverType: new FormControl(),
      mpdRevision: new FormControl(),
      msRevision: new FormControl(),
      msTemporaryRevision: new FormControl(),
      ntmRevision: new FormControl(),
      plant: new FormControl(),
      salesOrder: new FormControl('', Validators.required),
      srmRevision: new FormControl(),
      tsmRevision: new FormControl(),
      layoverEndDate: new FormControl({value:'',disabled:true}, Validators.required),
      originatingServiceOrder: new FormControl('', Validators.required),
       cri: new FormControl('', Validators.required)
    })

  }
  getAircraft(message) {
   // this.layoverData.controls['aircraft'].markAsDirty();
    this.layoverData.controls['aircraft'].setValue(message)
    this.layoverData.controls['aircraftId'].setValue(message.id)
    this.layoverData.controls['aircraftId'].markAsDirty();
  
  }

  dateSelected(date: string, fcName: string) {
    if (fcName == 'customerWPIssueDate') {
      this.customerWPIssueDate = date;
    }
    if (fcName == 'layoverStartDate') {
      this.layoverStartDate = date
    
    }
    //if (fcName == 'layoverEndDate') {
    //  this.layoverEndDate = date
    //}

  }
  cancel() {
    
    this.router.navigate(['/layover-management']);
  
  }
  convertStrintToDate(dateString) {
    let stringArr = dateString.split(".")
    let convertedDate = stringArr[2] + "-" + stringArr[1] + "-" + stringArr[0] +"T00:00:00.000Z"
    return convertedDate;

  }
  getDateWithoutH(dateString: string) {

    let date = new Date(dateString);
    let day = this.format_two_digits(date.getDate());
    let month = this.format_two_digits(date.getMonth() + 1);
    let year = date.getFullYear();


    let transformedDate = day + '.' + month + '.' + year

    return transformedDate;
  }
  format_two_digits(n) {
    return n < 10 ? '0' + n : n;
  }
  onSubmit(layoverData: FormGroup) {
    this.submitted = true;
    if (layoverData.dirty && this.isCreate && layoverData.valid) {
      
      let dataToSend: EventModel = this.fservice.submitChangedData(layoverData);

      if (dataToSend.customerWPIssueDate && this.customerWPIssueDate) {
        dataToSend.customerWPIssueDate = this.convertStrintToDate(this.customerWPIssueDate)
      }
      if (dataToSend.layoverStartDate && this.layoverStartDate) {
        dataToSend.layoverStartDate = this.convertStrintToDate(this.layoverStartDate)
      }
      if (dataToSend.layoverEndDate && this.layoverEndDate) {
        dataToSend.layoverEndDate = this.convertStrintToDate(this.layoverEndDate)
      }
      this.api.createEvent(dataToSend).subscribe(result => {
        this.alertMessage = "Successfully created layover!"
        this.isSuccess = true;
        this.showNotification = true;
        this.submitted = false;
      }, err => {
        this.alertMessage = "Something whent wrong,please try again!"
        this.isSuccess = false;
        this.showNotification = true;
      });
     
    }
    if (layoverData.dirty && !this.isCreate && layoverData.valid) {
      layoverData.controls['id'].markAsDirty();
      let dataToSend: EventModel = this.fservice.submitChangedData(layoverData);
      if (dataToSend.customerWPIssueDate && this.customerWPIssueDate) {
        dataToSend.customerWPIssueDate = this.convertStrintToDate(this.customerWPIssueDate)
      }
      if (dataToSend.layoverStartDate && this.layoverStartDate) {
        dataToSend.layoverStartDate = this.convertStrintToDate(this.layoverStartDate)
      }
      if (dataToSend.layoverEndDate && this.layoverEndDate) {
        dataToSend.layoverEndDate = this.convertStrintToDate(this.layoverEndDate)
      }
      this.api.updateEvent(dataToSend).subscribe(
        result => {
        this.alertMessage = "Successfully saved changes!"
        this.isSuccess = true;
          this.showNotification = true;
          this.submitted = false;
       
      },
        err => {
          this.alertMessage = "Something whent wrong,please try again!"
          this.isSuccess = false;
          this.showNotification = true;
        });
    }

  }
 
  ngOnInit() {
  
    this.routeParamsSubsc = this.route.params.subscribe(params => {
      if (params.id) {

        this.isCreate = false;
      
        this.eventSubscr = this.api.getEventById(Number(params.id)).subscribe(result => {
          if (result) {
         
            this.layoverData.patchValue(result[0])
            if (this.layoverData.controls['aircraft'].value) {
              this.layoverData.controls['aircraftId'].setValue(this.layoverData.controls['aircraft'].value.id)
            }
            //if (this.layoverData.controls['customerWPIssueDate'].value) {
            //  this.layoverData.controls['customerWPIssueDate'].setValue(this.getDateWithoutH(this.layoverData.controls['customerWPIssueDate'].value))
            //}
            //if (this.layoverData.controls['layoverStartDate'].value) {
            //  this.layoverData.controls['layoverStartDate'].setValue(this.getDateWithoutH(this.layoverData.controls['layoverStartDate'].value))
            //}
            //if (this.layoverData.controls['layoverEndDate'].value) {
            //  this.layoverData.controls['layoverEndDate'].setValue(this.getDateWithoutH(this.layoverData.controls['layoverEndDate'].value))
            //}
      
        
          }
        })
      } else {
        this.isCreate = true;
      }
     
      
    });

    this.startSubscr = this.layoverData.controls['layoverStartDate'].valueChanges.subscribe(data => {
    
      if (this.layoverData.controls['layoverStartDate'].value && this.tat) {
        let newDate: Date = new Date(this.layoverData.controls['layoverStartDate'].value)

        newDate.setDate(newDate.getDate() + Number(this.tat))
        this.layoverEndDate = newDate

        this.layoverData.controls['layoverEndDate'].setValue(this.layoverEndDate)
      }


    });
    this.tatSubscr = this.layoverData.controls['layoverTAT'].valueChanges.subscribe(data => {
      this.tat = data
     
      if (this.layoverData.controls['layoverTAT'].value && this.layoverData.controls['layoverStartDate'].value) {
        let newDate: Date = new Date(this.layoverData.controls['layoverStartDate'].value)
        newDate.setDate(newDate.getDate() + Number(this.tat))
        this.layoverEndDate = newDate
        this.layoverData.controls['layoverEndDate'].setValue(this.layoverEndDate)
      }


    });

  }
  ngOnDestroy() {
    this.startSubscr.unsubscribe();
    this.tatSubscr.unsubscribe();
    this.routeParamsSubsc.unsubscribe();
    if (this.eventSubscr) {
      this.eventSubscr.unsubscribe();
    }
  }
}
