import { Component, OnInit } from '@angular/core';
import { AdalService } from 'adal-angular4';
import { Router } from '@angular/router';
@Component({
  selector: 'app-auth-callback',
  template: `  <div class="background"></div> <div class="app-loading">
    <div class= "logo"> </div>
      <svg class= "spinner" viewBox="25 25 50 50" >
      <circle class="path" cx = "50" cy="50" r="20" fill="none" stroke-width="2" stroke-miterlimit="10" > </circle>
      </svg>
  </div>`
})
export class AuthCallbackComponent implements OnInit {
 
  constructor(private adal: AdalService, private router: Router) { }
 
  ngOnInit() {
   this.adal.handleWindowCallback();
    if (!this.adal.userInfo.authenticated) {
      this.adal.login();
     // this.router.navigate(['login']);
    } else {
      let storage = window.localStorage
      let redirect: string = storage.getItem('originalUrl');
      if (redirect) {
        window.location.href = redirect;
      } else {
          this.router.navigate(['home']);
      }
   
      
    }



  }
 
}
