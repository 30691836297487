import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { FixedSizeVirtualScrollStrategy, VIRTUAL_SCROLL_STRATEGY } from '@angular/cdk/scrolling';
import { Subscription, Observable } from 'rxjs';
import { NonRoutineItemService } from '../nonRoutineItem.service';
import { User} from '../models';
import { MatDialog } from '@angular/material/dialog';
import { DialogDeleteComponent } from '../common/dialogs/dialogs.component';

export class CustomVirtualScrollStrategy extends FixedSizeVirtualScrollStrategy {
  constructor() {
    super(0, 0, 0);
  }
}

@Component({
  selector: 'admin-panel',
  templateUrl: 'admin-panel.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: VIRTUAL_SCROLL_STRATEGY, useClass: CustomVirtualScrollStrategy }]
})

export class AdminPanelComponent implements OnInit {
  user;
  vUsers;
  usersData = [];
  allUsersSubscr: Subscription;
  constructor(private api: NonRoutineItemService, public dialog: MatDialog, private cdRef: ChangeDetectorRef) { }
 
  returnStations(arr) {
    
    let stations=[];
    arr.forEach(item => {
      stations.push(item.workStation.name)
    })


    return stations.join(", ")
  }

  deleteUser(id:number,e,i) {
    e.preventDefault();


    const dialogRef = this.dialog.open(DialogDeleteComponent, {
      width: '350px',
    });
    dialogRef.afterClosed().subscribe(result => {
      
      if (result) {
        this.usersData.splice(i, 1);
        this.usersData = [...this.usersData]
        this.cdRef.detectChanges();
        this.api.deleteUser(Number(id)).subscribe();
      }
    });
  }
  getSelectedUser(user) {
    this.user = user


  }
  
  
  ngOnInit() {
    this.allUsersSubscr = this.api.getUser().subscribe(result => {
      if (result) {
        this.usersData = result;
        
        this.cdRef.detectChanges();
       
      }
    });

  

  }
  ngOnDestroy() {
    this.allUsersSubscr.unsubscribe();
  }
 
}
