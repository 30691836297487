import { Component, OnInit, OnDestroy, ViewChild, Output, EventEmitter } from '@angular/core';
import {  Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NonRoutineItemService } from '../nonRoutineItem.service';
import { User, WorkStation, NonRoutineItem, InvolvedWorkStation, Defect, Notification } from '../models';
import { FormsService } from '../forms.service';
import { Observable, Subscription, from } from 'rxjs';

import { OverviewPresenter } from './overview.presenter';
import { OverviewComponent } from './overview.component';
import { map } from 'rxjs/operators';


@Component({
  selector: 'app-overview-container',
  templateUrl: 'overview.container.html',
  providers: [OverviewPresenter]
})
export class OverviewContainer implements OnInit, OnDestroy {
  @ViewChild(OverviewComponent, { static: false }) children: OverviewComponent;
  private idSubscribtion: Subscription;
  private defectIDSubscribtion: Subscription;
  private nriSubscribtion: Subscription;
  private isNewSubscribtion: Subscription;
  private ownerSubscribtion: Subscription;

  userSubscription: Subscription;
  currUser;
  owner;
  selectedOwner;
  showSpinner: boolean = true;
  @Output() isSuccess: EventEmitter<Notification> = new EventEmitter<Notification>(); 
  users$: Observable<User[]>;
  stationsData$: Observable<WorkStation[]>
 
  itemToSend: NonRoutineItem = {};
  submitted: boolean=false
  form: object;
  isCreate: boolean;
  isForEdit: boolean=false;
  formData: FormGroup;


  id;
  initialDefId;

  item;
  nriData;
  isNew: boolean;

  constructor(public router: Router,public formsService: FormsService, public NRIservice: NonRoutineItemService, private presenter: OverviewPresenter) { }
  getOwner(owner) {
    this.formData.controls['OwnerId'].markAsDirty();
    this.formData.controls['OwnerId'].setValue(owner.id)
    this.selectedOwner = owner;
   
   
  }
  leadingWC(wc) {
  
    this.formData.controls['LeadingWorkStationId'].markAsDirty();
    this.formData.controls['LeadingWorkStationId'].setValue(wc)
  
  }
  saveMultipleVals(arr) {
    let involved: InvolvedWorkStation[] = [];
    
    if (this.isForEdit) {
      arr.forEach(item => {
        involved.push({ NonRoutineItemId: Number(this.presenter.getItemId()), WorkStationId: item })
      })
    } else {
      arr.forEach(item => {
        involved.push({WorkStationId: item })
      })
    }
   
    this.formData.controls['InvolvedWorkStation'].markAsDirty();
    this.formData.controls['InvolvedWorkStation'].setValue(involved)
 
  }

  onSubmit(filledData: FormGroup) {
    this.submitted = true;
    if (filledData.valid) {
      const dataToSend: NonRoutineItem = this.formsService.submitChangedData(filledData);
      dataToSend.Status = "New";
      if (!this.presenter.isEmpty(dataToSend)) {
        this.NRIservice.saveItem(dataToSend).subscribe(
          result => {

            if (result) {
              let arr = [];

              arr.push(result)
              this.formsService.updateNriObject(arr)
              this.formsService.updateNewID(result.id)
              let newDefect: Defect = {};
              newDefect.nonRoutineItemId = this.id;
              this.NRIservice.createDefect(newDefect).subscribe(resultDef => {

                this.formsService.updateDefect([resultDef]);
              }, err => {
                this.isSuccess.emit({ message: 'Something whent wrong,could not create initial defect!Please try again!', isSuccess: false })
              })
              this.isNew = false;
              this.submitted = false;
            }
          }
        ), err => {
          this.isSuccess.emit({ message: 'Something whent wrong,please try again!', isSuccess: false })
        }
      }





    } 


   
  }
  onSaveChanges(message: FormGroup) {
 
    this.submitted = true;
    if (message.valid) {
      let workStation: InvolvedWorkStation[] = [];

      if (this.formData.controls['InvolvedWorkStation'].value == null || this.formData.controls['InvolvedWorkStation'].value == undefined) {
        this.nriData[0].involvedWorkStation.forEach(item => {
          workStation.push({ NonRoutineItemId: Number(this.presenter.getItemId()), WorkStationId: item.id })
        })
        this.formData.controls['InvolvedWorkStation'].markAsDirty();
        this.formData.controls['InvolvedWorkStation'].setValue(workStation)
      }
      const dataToSend: NonRoutineItem = this.formsService.submitChangedData(message);

      if (!this.presenter.isEmpty(dataToSend)) {
    
        dataToSend.id = Number(this.presenter.getItemId());
        this.NRIservice.updateItem(dataToSend).subscribe((data) => {
          this.nriSubscribtion = this.NRIservice.getNonRoutineItemById(this.presenter.getItemId()).subscribe(result => {
            this.nriData = result;
            this.formsService.updateOwner(this.selectedOwner);
            this.isSuccess.emit({ message: 'Successfully updated Non Routine item!', isSuccess: true })
          },

            err => {
              this.isSuccess.emit({ message: 'Something whent wrong,please try again!', isSuccess: false })
            });
          this.submitted = false;
        },
          err => {
            this.isSuccess.emit({ message: 'Something whent wrong,please try again!', isSuccess: false })
          }
        );
      }
      this.isForEdit = false
    } else {
      return false;
    }





  
    
  }
  onEdit(message: boolean) {
    if (message) {
      this.isForEdit = true
    }
    else {
      this.isForEdit = false
    }
  }

  ngOnInit() {
    this.formData = new FormGroup({


      Name: new FormControl('', Validators.required),
      OriginatingJobCard: new FormControl({value:"",disabled:true}, Validators.required),
      OriginatingWorkPack: new FormControl(),
      OwnerId: new FormControl('', Validators.required),
      InvolvedWorkStation: new FormControl(),
      LeadingWorkStationId: new FormControl('', Validators.required),
      Status: new FormControl(),
      Part: new FormControl(),
      PartId: new FormControl(),
      Quantity: new FormControl(),
      ModifiedBy: new FormControl(),
      CreatedBy: new FormControl(),
      EventId: new FormControl('', Validators.required),
      JobCardId: new FormControl() // jcId

    });


    this.userSubscription = this.formsService.castCurrentUser.subscribe(result => {
      if (result) {
     
        this.currUser = result[0];
      }
      if (result && this.isCreate) {
        this.formData.controls['OwnerId'].setValue(this.currUser.id);
        this.formData.controls['OwnerId'].markAsDirty();
        this.formData.controls['ModifiedBy'].markAsDirty();
        this.formData.controls['ModifiedBy'].setValue(this.currUser.name);
        this.formData.controls['CreatedBy'].markAsDirty();
        this.formData.controls['CreatedBy'].setValue(this.currUser.name);
        this.formsService.updateOwner(this.currUser);
        this.owner = this.currUser
      }

    })

    this.isNewSubscribtion = this.formsService.castIsNew.subscribe(isNew => this.isNew = isNew)
    this.idSubscribtion = this.formsService.castID.subscribe(id => this.id = id)
    this.defectIDSubscribtion = this.formsService.castInitialDefect.subscribe(id => this.initialDefId = id)

    this.ownerSubscribtion = this.formsService.castOwner.subscribe(owner => this.owner = owner)

    this.stationsData$ = this.NRIservice.getWorkStation();
    this.users$ = this.NRIservice.getUser();
  
    if (this.router.url.indexOf('create')>0) {
      this.formsService.updateIsNew(true)
      this.isCreate = true;
      this.showSpinner = false;
  
     
    } else {

      this.formsService.updateNewID(this.presenter.getItemId());
      this.isCreate = false;
      this.formsService.updateIsNew(false)
      //this.nriLayover$ = this.NRIservice.getLayovers();
      this.nriSubscribtion = this.NRIservice.getNonRoutineItemById(this.presenter.getItemId()).subscribe(result => {
        this.nriData = result;
 
        this.formsService.updateNriObject(this.nriData)
       
        this.selectedOwner = this.nriData[0].owner
      
        this.formsService.updateOwner(this.selectedOwner);
        this.showSpinner = false;
        this.formData.controls['Name'].setValue(result[0].name);
        this.formData.controls['OwnerId'].setValue(result[0].owner.id);
        this.formData.controls['LeadingWorkStationId'].setValue(result[0].leadingWorkStation.id);
        this.formData.controls['OriginatingWorkPack'].setValue(result[0].originatingWorkPack);
        this.formData.controls['OriginatingJobCard'].setValue(result[0].originatingJobCard);
        this.formData.controls['EventId'].setValue(result[0].event.id);
      },
        err => {
          this.isSuccess.emit({ message: 'Something whent wrong,could not get Non Routine item!Please try again!', isSuccess: false })
        });

      

    }
  
 

  

  }

  ngOnDestroy() {
   
    this.userSubscription.unsubscribe();
    this.isNewSubscribtion.unsubscribe();
    this.idSubscribtion.unsubscribe();
    this.defectIDSubscribtion.unsubscribe();
    if (this.nriSubscribtion) {
      this.nriSubscribtion.unsubscribe();
    }
    if (this.ownerSubscribtion) {
      this.ownerSubscribtion.unsubscribe();
    }
   
  }

}
