import { Component, Input, Output, EventEmitter} from '@angular/core';
import { FormControl } from '@angular/forms';

/**
 * @title Basic select
 */
@Component({
  selector: 'select-component',
  templateUrl: 'select.component.html'

})
export class SelectComponent{

 // singleSelect = new FormControl();
  multipleSelect = new FormControl();

  @Input() selectedMultiVal;
  @Input() selectedOption;
  @Input() stepIndex?: number;
  @Input() options;
  @Input() isMultiple: boolean;
  @Input() defType?;
  @Output() selected = new EventEmitter<object>();
  @Output() selectedMultiple = new EventEmitter<[]>();
  @Input() disabled: boolean;
  changeSelect(data) {

    this.selected.emit(data.value);
  }

  multipleSelection(data) {
    this.selectedMultiple.emit(this.multipleSelect.value)
  }

  public objectComparisonFunction = function (option, value): boolean {
    if (value) {
      return option.id == value.id;
    }
    
  }

  ngOnInit() {
   this.multipleSelect.setValue(this.selectedMultiVal)
    //this.singleSelect.setValue(this.selectedOption);
 
  }
}
