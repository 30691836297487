import { FormControl } from '@angular/forms';
import { Component, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { Parts } from '../../models';
import { NonRoutineItemService } from '../../nonRoutineItem.service';
import { FormsService } from '../../forms.service'
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';


@Component({
  selector: 'people-picker',
  templateUrl: './people-picker.component.html',
})
export class PeoplePickerComponent {
  @ViewChild(MatAutocompleteTrigger, { static: false }) autocomplete: MatAutocompleteTrigger;
  private _person;
  @Output() ownerChosen: EventEmitter<number> = new EventEmitter();
  @Output() empty: EventEmitter<boolean> = new EventEmitter();
  @Input() set selectedOwner(val) {
    this._person = val;
    this.peoplePickerControlDB.setValue(this._person)
  }
  get selectedOwner() {
    return this._person
  }
  @Input() users;
  @Input() type;
  dbUsers;
  constructor(private api: NonRoutineItemService) { }
  peoplePickerControl = new FormControl();
  peoplePickerControlDB = new FormControl();
  peoplepickerSubscr: Subscription;
  peoplepickerSubscrDB: Subscription;
  azureSubscr: Subscription;
  filtered;
  allDBUsers;
  filteredDBUsers
  displayFn(person?): string | undefined {
    return (person && person.displayName) ? person.displayName : person;
  }
  displayFnDB(person?): string | undefined {
    return (person && person.name) ? person.name : person;
  }
  getUser(user) {
  
    this.ownerChosen.emit(user)
  }
  getUsersVal() {
    if (this.peoplePickerControl.value.length > 0) {
      this.azureSubscr = this.api.getAzureUzers(this.peoplePickerControl.value).subscribe(result => {
        if (result) {
          this.users = result;
        }
      }) 
    }
       
  }

 
  getdbUsersVal() {
    if (!this.peoplepickerSubscrDB) {
      if (this.peoplePickerControlDB.value.length >= 3) {

        this.peoplepickerSubscrDB = this.api.getUser().subscribe(item => {

          this.allDBUsers = item
          this.filterDBUsers(this.peoplePickerControlDB.value.toLowerCase())
        })

      }

    }
    else {
      if (this.peoplePickerControlDB.value.length == 3) {

        this.peoplepickerSubscrDB = this.api.getUser().subscribe(item => {

          this.allDBUsers = item
          this.filterDBUsers(this.peoplePickerControlDB.value.toLowerCase())
        })
      }
      if (this.peoplePickerControlDB.value.length > 3) {

        this.filterDBUsers(this.peoplePickerControlDB.value.toLowerCase())

      }
      if (this.peoplePickerControlDB.value.length < 3) {
       
        this.autocomplete.closePanel();
      }

    }

    //this.peoplepickerSubscrDB = this.api.getUser().subscribe(result => {
    //  if (result) {
    //    this.dbUsers = result;
    //  }
    //})
  }

  filterDBUsers(searchTerm: string) {
    if (this.allDBUsers) {
      this.filteredDBUsers = this.allDBUsers.filter(option =>
        option.name.toLowerCase().includes(searchTerm) ||
        option.email.toLowerCase().includes(searchTerm)
      )
     
    }
  }
  ngOnInit() {
    this.peoplePickerControl.setValue(this.selectedOwner)
    this.peoplePickerControlDB.setValue(this.selectedOwner)


    this.peoplepickerSubscr = this.peoplePickerControl.valueChanges.subscribe(result => {
      if (result == '') {
        this.empty.emit(true)
      }

    })

  }
  ngOnDestroy() {
    if (this.peoplepickerSubscrDB) {
      this.peoplepickerSubscrDB.unsubscribe();
    }
    this.peoplepickerSubscr.unsubscribe();
  }
}
