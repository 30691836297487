import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, ViewChild } from '@angular/core';
import { FixedSizeVirtualScrollStrategy, VIRTUAL_SCROLL_STRATEGY } from '@angular/cdk/scrolling';
import { Subscription } from 'rxjs';
import {NonRoutineItemService } from '../../app/nonRoutineItem.service';
import { MatDialog } from '@angular/material/dialog';
import { DialogDeleteComponent } from '../common/dialogs/dialogs.component';

export class CustomVirtualScrollStrategy extends FixedSizeVirtualScrollStrategy {
  constructor() {
    super(0, 0, 0);
  }
}

@Component({
  selector: 'layover-management',
  templateUrl: 'layover-management.component.html',
  providers: [{ provide: VIRTUAL_SCROLL_STRATEGY, useClass: CustomVirtualScrollStrategy }]

})

export class LayoverManagementComponent{
  allLayoversSubscr: Subscription;
  excelDataSubscr: Subscription;
  constructor(private api: NonRoutineItemService, public dialog: MatDialog, private cdRef: ChangeDetectorRef) {
  }
  deleteUser(i) { }
 
  allLayovers = [];
  openDialog(e, i, id) {
    e.preventDefault();
    const dialogRef = this.dialog.open(DialogDeleteComponent, {
      width: '350px',
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.allLayovers.splice(i, 1);
        this.allLayovers = [...this.allLayovers]
        this.cdRef.detectChanges();
        this.api.deleteEvent(Number(id)).subscribe();
      }
    });
  }
  generateExcel(eventId: number) {
   
    this.excelDataSubscr = this.api.getExcelData(eventId).subscribe(result => {
      if (result) {
        let sampleArr = this.base64ToArrayBuffer(result);
        this.saveByteArray("LHT-SAP-Defects", sampleArr);
       
      }
    })

  }
//Convert byteArrray 
 base64ToArrayBuffer(base64) {
  let binaryString = window.atob(base64);
   let binaryLen = binaryString.length;
   let bytes = new Uint8Array(binaryLen);
   for (let i = 0; i < binaryLen; i++) {
    var ascii = binaryString.charCodeAt(i);
    bytes[i] = ascii;
  }
  return bytes;
}

//Create 'Save as' functionality
 saveByteArray(reportName, byte) {
   let fileName = reportName + ".xlsx";
   let blob = new Blob([byte]);
  if (navigator.appVersion.toString().indexOf('.NET') > 0) {
    window.navigator.msSaveOrOpenBlob(blob, fileName);
  }
  else {
    let link = document.createElement('a');
    document.body.appendChild(link);
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName;
    link.click();
    document.body.removeChild(link);
  }
};
  checkActiveDates(startPeriodDate, endPeriodDate) {

    let minDate = new Date(startPeriodDate);
    let maxDate = new Date(endPeriodDate);
    let currDate = new Date();
    if (currDate > minDate && currDate < maxDate) {
      return true;
    }
    else {
      return false;
    }


  }
  ngOnInit() {
   
    
    this.allLayoversSubscr = this.api.getAllEvents().subscribe(result => {
      if (result) {
        this.allLayovers = result;     
      }
    })
  }
  ngOnDestroy() {
    if (this.excelDataSubscr) {
      this.excelDataSubscr.unsubscribe();
    }
    this.allLayoversSubscr.unsubscribe();


  }
}
