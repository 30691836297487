import { Component, Input, EventEmitter, Output, ViewChild, ViewContainerRef } from '@angular/core';
import { DefectType, Parts, DefectInfo, Defect } from '../models';
import { WhatWherePresenter } from './what-where.presenter';
import { Subject, Subscription } from 'rxjs';
import { FormGroup, NgForm, FormArray, FormBuilder, FormControl} from '@angular/forms';
import { FormsService } from '../forms.service';
import { NonRoutineItemService } from '../nonRoutineItem.service';
import { DomSanitizer } from '@angular/platform-browser';
import { MatDialog } from '@angular/material/dialog';
import { DialogDeleteComponent } from '../common/dialogs/dialogs.component';


/**
 * @title Basic patch
 */
@Component({
  selector: 'what-where-component',
  templateUrl: 'what-where.component.html',
  providers: [WhatWherePresenter]

})
export class WhatWhereComponent {
  @ViewChild('f', { static: false }) public form: NgForm;
  @ViewChild('section', { static: false }) public section;
  //@ViewChild('subsection', { static: false }) public subsection;
  @ViewChild('chooseImg', { static: false }) public chooseImg;
  @Input() typeOptions;
  @Input() repairOptions;
  @Input() internalOptions;

  @Input() defectData;
  @Input() metricSys: boolean;
  @Input() formData: FormGroup;
  @Input() defectsListArray;
  @Input() defectFields
  @Input() id: number;
  @Input() edgesData;
  @Input() disableFormFields:boolean;
  @Output() selectedType: EventEmitter<string> = new EventEmitter();
  @Output() partId: EventEmitter<number> = new EventEmitter();
  @Output() onSubmit: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();

  chapter;
  imgIndex: number = 0;
  loadQuantity: number;
  allTypes;
  sketches;
  max;
  min;
  public spanName: any = 'Expand';
  public spanAll: any = 'Expand'
  private destroy: Subject<void> = new Subject();
  sketchItems = [];
  sketchOptions;
  sketchNames;
  isExpanded: boolean = false;
  showAllTypes: boolean = true;
  showAllTypesInitial: boolean = true;
  public show: boolean = false;
  public showAllDiv: boolean = false;
  hidePosition: boolean = false;
  isInletCowl: boolean = false;

  defectSubscription: Subscription;
  sectionsSubscription: Subscription;
  ataSubscription: Subscription;
  ataObjectSubscr: Subscription;
  ataByPartSubscr: Subscription;
  partObjectSubscr: Subscription;
  sketchObjSubscr: Subscription;
  allTypesObjSubscr: Subscription;
  sketchSubscribtion: Subscription;
  nriObjSubscr: Subscription;
  allSketches;
  selectedSketch;
  otherOptions: DefectType[];
  commonOptions: DefectType[];
  partObject;
  nriObj;
  initialType;
  ataChaptersVal;
  def: DefectInfo[];
  subTypes;
  subTypeSelected
  mainInitialType;
  general
  generalSketch;
  wingOptions = [{ id: "STR", name: "STR" }, { id: "SPAR", name: "SPAR" }]
  membersNames;
  sectionsOptions = [];
  selectedSection;
  wingSecondMember;



  constructor(
    public dialog: MatDialog,
    private presenter: WhatWherePresenter,
    private service: NonRoutineItemService,
    private fservice: FormsService,
    private fb: FormBuilder,
    private domSanitizer: DomSanitizer) {

  }
  getExistingRepair(message, fgItem: FormGroup) {

    fgItem.controls['existingRepair'].markAsDirty();
    if (message.name == 'Yes' && message.checked) {
      fgItem.controls['existingRepair'].setValue(true)
    }
    if (message.name == 'No' && message.checked) {
      fgItem.controls['existingRepair'].setValue(false)
    }
    if (!message.checked) {
      fgItem.controls['existingRepair'].setValue(null)
    }
  }
  getInternal(message, fgItem: FormGroup) {
   

    fgItem.controls['internal'].markAsDirty();
    if (message.name == 'Yes' && message.checked) {
      fgItem.controls['internal'].setValue(true)
    }
    if (message.name == 'No' && message.checked) {
      fgItem.controls['internal'].setValue(false)
    }
    if (!message.checked) {
      fgItem.controls['internal'].setValue(null)
    }


  }
  selectEdge(val, control: FormControl) {
    control.setValue(val.id)
  }
  updateValue(fgItem: FormGroup, e, controlName: string) {
    if (!this.metricSys) {
      fgItem.controls[controlName].markAsDirty();
      fgItem.controls[controlName].setValue(Number(this.presenter.convertInchesToMm(Number(e.value))))
    } else {
      fgItem.controls[controlName].markAsDirty();
      fgItem.controls[controlName].setValue(Number(e.value))
    }


  }
  clearComment(val, control: FormControl) {
    if (!val) {
      control.setValue(null)
    }

  }
  convertValue(val) {
    if (val && val!='') {
      if (!this.metricSys) {
        return this.presenter.convertMmToInches(val)
      } else {
        return val
      }
    }
   
  }

  selectImage(e, imgObj) {
    e.preventDefault();
    this.defectsListArray.controls.forEach((item, index) => {
      let currControl = <FormGroup>item
      currControl.controls['secondPosition'].markAsDirty();
      currControl.controls['secondPosition'].setValue(null)
    })
    this.defectsListArray.controls.forEach((item, index) => {
      let currControl = <FormGroup>item
      currControl.controls['firstPosition'].markAsDirty();
      currControl.controls['firstPosition'].setValue(null)
    })
    this.selectedSketch = imgObj
    this.membersNames = imgObj
    this.checkForInletCowl(imgObj)
    this.wingSecondMember = null;
    this.defectsListArray.controls.forEach((item, index) => {
      let currControl = <FormGroup>item
      this.clearPositionFields(currControl)
      currControl.controls['sketchId'].markAsDirty();
      currControl.controls['sketchId'].setValue(imgObj.id)
    })
    this.hidePosition = false;
    if (this.selectedSketch.name == 'General') {
      this.general = true;
    } else {
      this.general = false;
    }
  }

  clearPositionFields(formGr: FormGroup) {
    formGr.controls['secondMemberType'].reset();
    formGr.controls['closestSecond'].reset();
    formGr.controls['closestFirst'].reset();
    formGr.controls['fromEdge'].reset();
    formGr.controls['toEdge'].reset();
    formGr.controls['fromEdgeId'].reset();
    formGr.controls['toEdgeId'].reset();
    formGr.controls['firstPosition'].reset();
    formGr.controls['secondPosition'].reset();
    formGr.controls['fromMemberFirst'].reset();
    formGr.controls['toMemberFirst'].reset();
    formGr.controls['valueFirst'].reset();
    formGr.controls['fromMemberSecond'].reset();
    formGr.controls['toMemberSecond'].reset();
    formGr.controls['valueSecond'].reset();
    formGr.controls['fromExtra'].reset();
    formGr.controls['fromExtraValue'].reset();

  }

  getFirstPosition(position) {
    this.defectsListArray.controls.forEach((item, index) => {
      let currControl = <FormGroup>item
      currControl.controls['firstPosition'].markAsDirty();
      currControl.controls['firstPosition'].setValue(position.name)
    })
  }

  getSecondPosition(position) {
    this.defectsListArray.controls.forEach((item, index) => {
      let currControl = <FormGroup>item
      currControl.controls['secondPosition'].markAsDirty();
      currControl.controls['secondPosition'].setValue(position.name)
    })
  }

  discardImage(e) {
    e.preventDefault();
    this.selectedSketch = null;

    this.hidePosition = true;
    this.defectsListArray.controls.forEach((item, index) => {
      let currControl = <FormGroup>item
      this.clearPositionFields(currControl)
      currControl.controls['sketchId'].markAsDirty();
      currControl.controls['sketchId'].setValue(null)
    })
    this.defectsListArray.controls.forEach((item, index) => {
      let currControl = <FormGroup>item
      currControl.controls['secondPosition'].markAsDirty();
      currControl.controls['secondPosition'].setValue(null)
    })
    this.defectsListArray.controls.forEach((item, index) => {
      let currControl = <FormGroup>item
      currControl.controls['firstPosition'].markAsDirty();
      currControl.controls['firstPosition'].setValue(null)
    })
  }

  changeSection(section) {
    this.selectedSection = section;
    this.sketchOptions = [];
    this.checkSketchSubscr();
  }

  changeChapter(ata) {
    this.chapter = ata.chapter
    this.getSections(ata.chapter)
    this.checkSketchSubscr();
  }

  checkSketchSubscr() {
    if (!this.sketchSubscribtion) {
      this.sketchSubscribtion = this.service.getSketch().subscribe(sketches => {
        this.allSketches = sketches;
        this.sketches = this.allSketches.filter(item => item.name !== 'General')
        this.generalSketch = this.allSketches.filter(item => item.name == 'General')[0]
        this.checkForGeneralImg();
        this.ataChapter(event);
      });
    } else {
      this.ataChapter(event);
    }
  }

  getSections(chapter) {
    this.sectionsSubscription = this.service.getAtaSection(chapter).subscribe(result => {
      this.sectionsOptions = [];
      result.forEach(item => {
        this.sectionsOptions.push({ id: item.section, name: item.section + '-' + item.designation });
      })
    })
  }

  ataChapter(e) {
    if (this.sectionsSubscription && this.selectedSection && this.selectedSection.name) {
      let ataChapterInput = this.chapter + "/" + this.selectedSection.id
      this.ataSubscription = this.service.getSketchByAtaChapter(ataChapterInput).subscribe(result => {
        this.sketchOptions = result
       
        if (this.sketchOptions.length == 1) {
     
          this.checkForInletCowl(this.sketchOptions[0])
          this.defectsListArray.controls.forEach((item, index) => {
            let currControl = <FormGroup>item
            currControl.controls['fromEdgeId'].reset();
            currControl.controls['toEdgeId'].reset();
          })

          this.getSketchesForAta(event, this.sketchOptions[0].sketchId, this.sketchOptions[0])
          this.formData.controls['ataObject'].setValue(this.sketchOptions[0])
        }
      })
    }
  }

  getSketchName(id: number) {
    let name: string;
    this.sketches.forEach((item) => {
      if (item.id == id) {
        name = item.name
      }
    })
    return name
  }

  getSketchesForAta(e, id: number, ataObj) {
    
    this.formData.controls['ataObject'].setValue(ataObj)
    this.defectsListArray.controls.forEach((item, index) => {
      let currControl = <FormGroup>item
      currControl.controls['sketchId'].markAsDirty();
      currControl.controls['sketchId'].setValue(id)
    })
    if (id) {
      document.querySelectorAll('.selectedSketch').forEach(item => {
        item.classList.remove('selectedSketch')
      })
      if (e.srcElement.classList) {
        e.srcElement.classList.add('selectedSketch')
      }
      let indexes = [];
      this.sketches.forEach((item, index) => {
        if (item.id == id) {
          indexes.push(index)
        }
      })
      if (indexes.length > 0) {
        this.imgIndex = indexes[0];
        this.membersNames = this.sketches[this.imgIndex]
        this.selectedSketch = this.sketches[this.imgIndex]
      }
      this.hidePosition = false;
      
    } else {
      this.selectedSketch = null;
    }
    if (this.sketches[this.imgIndex].name == 'General') {
      this.general = true;
    } else {
      this.general = false;
    }
    this.checkForInletCowl(this.sketches[this.imgIndex])
   
  }

  getQuantity(quantity: number) {
    this.formData.controls["quantity"].setValue(quantity);
    this.formData.controls["quantity"].markAsDirty();
    //this.defectsListArray.controls.forEach((item, index) => {
    //  let currControl = <FormGroup>item
    //  currControl.controls['quantity'].markAsDirty();
    //  currControl.controls['quantity'].setValue(quantity)
    //});
  }
  getDefQuantity(quantity: number, fgItem: FormGroup) {
    fgItem.controls['quantity'].markAsDirty();
    fgItem.controls['quantity'].setValue(quantity)
  }
  openDialog(e, i, id) {
    e.preventDefault();
    const dialogRef = this.dialog.open(DialogDeleteComponent, {
      width: '350px',
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.defectsListArray.removeAt(i)
        this.service.deleteDefect(Number(id)).subscribe();
      }
    });
  }

  changeInitialType(type) {
    if (type.defectSubTypes && type.defectSubTypes.length > 0) {
      this.subTypes = type.defectSubTypes
    } else {
      this.subTypes = null;
    }
    this.initialType = type;
    let firstItem = <FormGroup>this.defectsListArray.controls[0];
    firstItem.controls['defectType'].setValue(type)
    firstItem.controls['defectTypeId'].markAsDirty();
    firstItem.controls['defectTypeId'].setValue(type.id);
    firstItem.controls['defectDimensions'].markAsDirty();
    firstItem.controls['defectDimensions'].setValue([]);
    if (!firstItem.controls['defectType'].value.defectSubTypes || firstItem.controls['defectType'].value.defectSubTypes.length==0) {
      firstItem.controls['defectSubTypeId'].setValue(null);
    }
    this.defectsListArray.controls.forEach((item, index) => {
      let currControl = <FormGroup>item
      if (!currControl.controls['defectType'].value || currControl.controls['defectType'].value.id == 0) {
        currControl.controls['defectType'].setValue(this.initialType)
        currControl.controls['defectTypeId'].markAsDirty();
        currControl.controls['defectTypeId'].setValue(this.initialType.id)
        currControl.controls['defectSubTypes'].setValue(this.returnTypes(currControl.controls['defectType'].value.id))
      }
    });

    if (type.name != "Broken Part" && firstItem.controls['part'].value && firstItem.controls['part'].value.id!=0) {
      firstItem.controls['partId'].setValue(null)
      firstItem.controls['partId'].markAsDirty();
      firstItem.controls['quantity'].setValue(null)
      firstItem.controls['quantity'].markAsDirty();

    }
  }

  changeInitialSubType(subtype) {
    let firstItem = <FormGroup>this.defectsListArray.controls[0];
    firstItem.controls['defectSubType'].setValue(subtype)
    firstItem.controls['defectSubTypeId'].markAsDirty();
    firstItem.controls['defectSubTypeId'].setValue(subtype.id);
    this.subTypeSelected = subtype
    this.defectsListArray.controls.forEach((item, index) => {
      let currControl = <FormGroup>item
      if (currControl.controls['defectType'].value && (!currControl.controls['defectSubType'].value) && currControl.controls['defectType'].value.id == subtype.defectTypeId) {
        currControl.controls['defectSubType'].setValue(subtype)
        currControl.controls['defectSubTypeId'].markAsDirty();
        currControl.controls['defectSubTypeId'].setValue(subtype.id)      
      }
    })
  }


  discardPart(e) {
    e.preventDefault();
    this.formData.controls['partObject'].setValue(null)
    this.formData.controls['partObject'].markAsDirty();
    this.formData.controls['quantity'].setValue(null)
    this.formData.controls['quantity'].markAsDirty();
   
    this.partObject = null;
   
  }
  discardDefPart(e, fgItem: FormGroup) {
    e.preventDefault();
    fgItem.controls['part'].setValue(null);
    fgItem.controls['part'].markAsDirty();
    fgItem.controls['partId'].setValue(null);
    fgItem.controls['partId'].markAsDirty();
    fgItem.controls['quantity'].setValue(null)
    fgItem.controls['quantity'].markAsDirty();
  }

  getDefMainType(message: any, formGroup?: FormGroup) {
    if (formGroup) {
      formGroup.controls['mainType'].markAsDirty();
      formGroup.controls['mainType'].setValue(message.name)
      formGroup.controls['commonTypes'].setValue([]);
      formGroup.controls['otherTypes'].setValue([]);
    

      if (message.id == 'AVI' && message.checked == true) {
        formGroup.controls['commonTypes'].setValue(this.presenter.filterCommonTypes(this.allTypes, 'isAVI'))
        formGroup.controls['otherTypes'].setValue(this.presenter.filterOtherTypes(this.allTypes, 'isAVI'));
        formGroup.controls['remaining'].setValue(null);
        formGroup.controls['nominal'].setValue(null);
        formGroup.controls['skinThickness'].setValue(null);
      }
      if (message.id == 'SYS' && message.checked == true) {
        formGroup.controls['commonTypes'].setValue(this.presenter.filterCommonTypes(this.allTypes, 'isSYS'))
        formGroup.controls['otherTypes'].setValue(this.presenter.filterOtherTypes(this.allTypes, 'isSYS'));
        formGroup.controls['remaining'].setValue(null);
        formGroup.controls['nominal'].setValue(null);
        formGroup.controls['skinThickness'].setValue(null);
      }
      if (message.id == 'STR' && message.checked == true) {
        formGroup.controls['commonTypes'].setValue(this.presenter.filterCommonTypes(this.allTypes, 'isSTR'))
        formGroup.controls['otherTypes'].setValue(this.presenter.filterOtherTypes(this.allTypes, 'isSTR'));      
      }
      if (message.checked == false) {
        formGroup.controls['showAllTypes'].setValue(true);
        formGroup.controls['mainType'].markAsDirty();
        formGroup.controls['mainType'].setValue(null);
      } else {
        formGroup.controls['showAllTypes'].setValue(false);
      } 
    } else {
      let firstItem = <FormGroup>this.defectsListArray.controls[0];
      firstItem.controls['mainType'].markAsDirty();
      firstItem.controls['mainType'].setValue(message.name)
      this.mainInitialType = message.name
      this.commonOptions = [];
      this.otherOptions = [];
      if (message.id == 'AVI' && message.checked == true) {
        this.commonOptions = this.presenter.filterCommonTypes(this.allTypes, 'isAVI')
        this.otherOptions = this.presenter.filterOtherTypes(this.allTypes, 'isAVI');
        firstItem.controls['remaining'].setValue(null);
        firstItem.controls['nominal'].setValue(null);
        firstItem.controls['skinThickness'].setValue(null);
      }
      if (message.id == 'SYS' && message.checked == true) {
        this.commonOptions = this.presenter.filterCommonTypes(this.allTypes, 'isSYS')
        this.otherOptions = this.presenter.filterOtherTypes(this.allTypes, 'isSYS')
        firstItem.controls['remaining'].setValue(null);
        firstItem.controls['nominal'].setValue(null);
        firstItem.controls['skinThickness'].setValue(null);
      }
      if (message.id == 'STR' && message.checked == true) {
        this.commonOptions = this.presenter.filterCommonTypes(this.allTypes, 'isSTR')
        this.otherOptions = this.presenter.filterOtherTypes(this.allTypes, 'isSTR')
      }
      if (message.checked == false) {
        this.showAllTypesInitial = true;
        firstItem.controls['mainType'].markAsDirty();
        firstItem.controls['mainType'].setValue(null)
      } else {
        this.showAllTypesInitial = false;
      }
    }
  }

  partChosen(message) {
    this.isExpanded = true;
    this.partObject = message

    this.formData.controls['partObject'].setValue(message)
    this.formData.controls['partObject'].markAsDirty();
    this.formData.controls['quantity'].setValue(1)
    this.formData.controls['quantity'].markAsDirty(); 
    if (message.ataSection && message.ataChapter) {
      this.changeChapter({ chapter: message.ataChapter })  
      this.changeSection(message.ataSection)
      this.ataByPartSubscr = this.service.getAtaObject(message.ataChapter, message.ataSection).subscribe(result => {
        if (result) {
            let sectionObj = {
             id: result[0].section,
             name: result[0].section + '-' + result[0].designation
          }
          this.changeChapter({ chapter: result[0].chapter })
          this.changeSection(sectionObj)
        }        
     });
    }  
  }
  partDefChosen(message, fgItem: FormGroup) {
    fgItem.controls['part'].setValue(message)
     
    fgItem.controls['partId'].setValue(message.id)
    fgItem.controls['partId'].markAsDirty();
    fgItem.controls['quantity'].setValue(1)
    fgItem.controls['quantity'].markAsDirty();  

  }
  toggle() {
    this.show = !this.show;
    if (this.show)
      this.spanName = "Collapse";
    else
      this.spanName = "Expand";
  }

  toggleAll() {
    this.showAllDiv = !this.showAllDiv;
    if (this.showAllDiv)
      this.spanAll = "Collapse";
    else
      this.spanAll = "Expand";
  }

  getDefType(message: any) {
    this.selectedType.emit(message);
    this.defectsListArray.controls.forEach((item, index) => {
      let currControl = <FormGroup>item
      if (!currControl.controls['defectType'].value || currControl.controls['defectType'].value.id == 0) {
        currControl.controls['defectType'].setValue(message)
        currControl.controls['defectTypeId'].markAsDirty();
        currControl.controls['defectTypeId'].setValue(message.id)
      }
    })
  }

  addPanel(e) {
    e.preventDefault();
    this.createDefect();
  }

  createDefect() {
    let newDefect: Defect = {};
    newDefect.nonRoutineItemId = this.id;
    this.service.createDefect(newDefect).subscribe(result => {
      this.defectsListArray.push(this.defectFields())
      let control = <FormGroup>this.defectsListArray.controls[this.defectsListArray.controls.length - 1]
      control.controls['id'].markAsDirty();
      control.controls['id'].setValue(result.id);
      //if (this.formData.controls['partObject'].value) {
      //  control.controls['partId'].markAsDirty();
      //  control.controls['partId'].setValue(this.formData.controls['partObject'].value.id);
      //}
      if (this.formData.controls['ataObject'].value) {
        control.controls['ataChapterId'].markAsDirty();
        control.controls['ataChapterId'].setValue(this.formData.controls['ataObject'].value.id);
      }
      if (!control.controls['defectType'].value && this.initialType) {  
        control.controls['defectTypeId'].markAsDirty();
        control.controls['defectTypeId'].setValue(this.initialType.id);
        control.controls['defectType'].setValue(this.initialType);
        if (this.subTypeSelected) {
          control.controls['defectSubTypeId'].markAsDirty();
          control.controls['defectSubTypeId'].setValue(this.subTypeSelected.id);
          control.controls['defectSubType'].setValue(this.subTypeSelected);
        }  
        control.controls['defectSubTypes'].setValue(this.returnTypes(this.initialType.id));
      }
      //if (control.controls['quantity'].value == null) {
      //  control.controls['quantity'].markAsDirty();
      //  control.controls['quantity'].setValue(this.loadQuantity);
      //}
      if (!control.controls['mainType'].value) {
        control.controls['mainType'].markAsDirty();
        control.controls['mainType'].setValue(this.mainInitialType);
      }
    });
  }



  prevImage(e) {
    e.preventDefault();
    if (this.chooseImg) {
      this.chooseImg.nativeElement.classList.remove('selectedImg')
    }

    if (!this.sketchSubscribtion) {
      this.sketchSubscribtion = this.service.getSketch().subscribe(sketches => {
        this.allSketches = sketches
        this.sketches = this.allSketches.filter(item => item.name !== 'General')
        this.generalSketch = this.allSketches.filter(item => item.name == 'General')[0]
        this.movePrevImage();
      });
    }
    else {
      this.movePrevImage();
    }
  }

  nextImage(e) {
    e.preventDefault();
    if (this.chooseImg) {
      this.chooseImg.nativeElement.classList.remove('selectedImg')
    }
 
    if (!this.sketchSubscribtion) {
      this.sketchSubscribtion = this.service.getSketch().subscribe(sketches => {
        this.allSketches = sketches
        this.sketches = this.allSketches.filter(item => item.name !== 'General')
        this.generalSketch = this.allSketches.filter(item => item.name == 'General')[0]
        this.moveNextImage();
      });
    }
    else {
      this.moveNextImage();
    }
  }


  movePrevImage() {
    this.checkForGeneralImg();
    if (this.sketches.length == 1) {
      this.sketches = this.allSketches

    }
    if (this.imgIndex == 0) {
      this.imgIndex = this.sketches.length - 1
    } else {
      this.imgIndex--;
    }
  
    let firstItem = <FormGroup>this.defectsListArray.controls[0];
    if (firstItem.controls['mainType'].value == 'STR') {
      this.ataSubscription = this.service.getAtaBySketch(this.sketches[this.imgIndex].id).subscribe(result => {
        if (result && result.length > 0) {
          this.sectionsOptions = [];
          this.ataChaptersVal = result;
          this.chapter = result[0].chapter
          this.ataChaptersVal.forEach(item => {
            this.sectionsOptions.push({ id: item.section, name: item.section + '-' + item.designation })
          })
        }
      })
    }
  
  }

  moveNextImage() {
    this.checkForGeneralImg();
    if (this.sketches.length == 1) {
      this.sketches = this.allSketches

    }
    this.max = this.sketches.length - 1;
    if (this.imgIndex < this.max) {
      this.imgIndex++;
    } else {
      this.imgIndex = 0;
    }
    let firstItem = <FormGroup>this.defectsListArray.controls[0];
    if (firstItem.controls['mainType'].value == 'STR') {
      this.ataSubscription = this.service.getAtaBySketch(this.allSketches[this.imgIndex].id).subscribe(result => {
        if (result && result.length > 0) {
          this.sectionsOptions = [];
          this.ataChaptersVal = result;
          this.chapter = result[0].chapter
          this.ataChaptersVal.forEach(item => {
            this.sectionsOptions.push({ id: item.section, name: item.section + '-' + item.designation })
          })
        }
      })
    }
    
  }

  checkForGeneralImg() {
    if ((this.formData.controls['partObject'].value && this.formData.controls['partObject'].value.id !== 0) && this.sketches.filter(item => item.name == 'General').length == 0) {
      this.sketches.push(this.generalSketch)
    }
    if ((!this.formData.controls['partObject'].value || (this.formData.controls['partObject'].value && this.formData.controls['partObject'].value.id == 0)) && this.sketches.filter(item => item.name == 'General').length !== 0) {
      this.sketches = this.allSketches.filter(item =>
        item.name !== 'General'
      )
    }
  }

  checkForInletCowl(image) {
    if (image.name == "Inlet cowl") {
      this.isInletCowl = true;
    } else if (this.isInletCowl) {
      this.isInletCowl = false;
    }
  }
  findIndexToUpdate(newItem) {
    return newItem.dimensionId === this;
  }

  changeDimension(e, formGroupItem: FormGroup) {

    const dataset = e.target.dataset;
    let convertVal;
    if (!this.metricSys) {
      convertVal = this.presenter.convertInchesToMm(Number(e.target.value))
    } else {
      convertVal = Number(e.target.value)
    }
    if (dataset) {
      let defectToPush = {
        defectId: formGroupItem.controls['id'].value,
        dimensionId: Number(dataset['dimenstionId']),
        value: convertVal
      }
      if (formGroupItem.controls['defectDimensions'].value) {
        this.setDimension(e, formGroupItem.controls['defectDimensions'].value, dataset, defectToPush, formGroupItem)
      } else {
        let emptyArr = [];
        this.setDimension(e, emptyArr, dataset, defectToPush, formGroupItem)
      }
    }
  }

  setDimension(e, arr, dataset, defectToPush, formGroupItem) {
    let dimentionsArr = arr;
    let updateItem = dimentionsArr.find(this.findIndexToUpdate, defectToPush.dimensionId);
    let index = dimentionsArr.indexOf(updateItem);
    if (index == -1) {
      dimentionsArr.push({
        defectId: formGroupItem.controls['id'].value,
        dimensionId: Number(dataset['dimenstionId']),
        value: Number(e.target.value)
      })
    } else {
      dimentionsArr[index] = defectToPush;
    }
    formGroupItem.controls['defectDimensions'].setValue(dimentionsArr)
    formGroupItem.controls['defectDimensions'].markAsDirty();
  }

  returnDimensionVal(dimensions, vals) {
    let val;
    if (vals) {
      vals.forEach(item => {
        if (item.dimensionId == dimensions.id) {
          val = item.value
        }
      })
    }
    if (val) {
      if (!this.metricSys) {
        return this.presenter.convertMmToInches(val)
      } else {
        return val
      }
    } else {
      return ''
    }
  }

  changeCurrSubType(type, formGroupItem: FormGroup) {
    formGroupItem.controls['defectSubType'].setValue(type);
    formGroupItem.controls['defectSubTypeId'].markAsDirty();
    formGroupItem.controls['defectSubTypeId'].setValue(type.id);
  }

  changeCurrType(type, formGroupItem: FormGroup) {   
    formGroupItem.controls['defectSubTypes'].setValue(type.defectSubTypes) 
    formGroupItem.controls['defectSubTypeId'].setValue(null)
    formGroupItem.controls['defectTypeId'].markAsDirty();
    formGroupItem.controls['defectDimensions'].markAsPristine();
    formGroupItem.controls['defectDimensions'].setValue([])
    formGroupItem.controls['defectTypeId'].markAsDirty();
    formGroupItem.controls['defectType'].setValue(type)
    formGroupItem.controls['defectTypeId'].setValue(type.id)

    if (type.name != "Broken Part" && formGroupItem.controls['part'].value && formGroupItem.controls['part'].value.id!=0) {
      formGroupItem.controls['partId'].markAsDirty();
      formGroupItem.controls['partId'].setValue(null)
   
      formGroupItem.controls['quantity'].markAsDirty();
      formGroupItem.controls['quantity'].setValue(null)
    }
  }

  saveMemeberFirst(data, formGroupItem: FormGroup) {
    formGroupItem.controls['closestFirst'].markAsDirty();
    formGroupItem.controls['closestFirst'].setValue(Number(data.name));
  }

  saveMemeberSecond(data, formGroupItem: FormGroup) {
    formGroupItem.controls['closestSecond'].markAsDirty();
    formGroupItem.controls['closestSecond'].setValue(data.name);
  }

  saveSecondMemberType(data, formGroupItem: FormGroup) {
    this.wingSecondMember = data.name;
    this.defectsListArray.controls.forEach((item, index) => {
      let currControl = <FormGroup>item
      currControl.controls['secondMemberType'].markAsDirty();
      currControl.controls['secondMemberType'].setValue(data.name)
    })
  }

  returnTypes(id) {
    if (this.allTypes) {
      if (id) {
        return this.allTypes.filter(item => item.id == id)[0].defectSubTypes
      }
    }
  }

  ngOnInit() {
    this.nriObjSubscr = this.fservice.castNriObject.subscribe(result => {
      if (result) {
        this.nriObj = result
        if (this.nriObj[0] && this.nriObj[0].part) {
          this.partObject = this.nriObj[0].part

          if (this.partObject) {
            if (this.partObject.name) {
              this.isExpanded = true;
            } else {
              this.isExpanded = false;
            }

            this.formData.controls['quantity'].setValue(this.nriObj[0].quantity)
          }


        }
      }
    });


    this.ataObjectSubscr = this.formData.controls['ataObject'].valueChanges.subscribe(val => {
      if (val) {
      
        if (this.section.nativeElement.value == "" || (this.chapter == null || this.chapter == undefined)) {
          this.section.nativeElement.value = this.formData.controls['ataObject'].value.section
          this.chapter = this.formData.controls['ataObject'].value.chapter      
        }
        this.defectsListArray.controls.forEach((item, index) => {
          let currControl = <FormGroup>item
          currControl.controls['ataChapterId'].markAsDirty();
          currControl.controls['ataChapterId'].setValue(val.id)
        })
      } else {
        this.defectsListArray.controls.forEach((item, index) => {
          let currControl = <FormGroup>item
          currControl.controls['ataChapterId'].markAsDirty();
          currControl.controls['ataChapterId'].setValue(null)
        })
      }  
    })
    //this.partObjectSubscr = this.formData.controls['partObject'].valueChanges.subscribe(val => {
    //  if (val) {
       
    //    this.defectsListArray.controls.forEach((item, index) => {
    //      let currControl = <FormGroup>item
    //      currControl.controls['partId'].markAsDirty();
    //      currControl.controls['partId'].setValue(val.id)
    //      currControl.controls['quantity'].markAsDirty();
    //      currControl.controls['quantity'].setValue(1)
    //    })
    //  } else {
    //    this.defectsListArray.controls.forEach((item, index) => {
    //      let currControl = <FormGroup>item
    //      currControl.controls['partId'].markAsDirty();
    //      currControl.controls['partId'].setValue(null)
    //    })
    //  }
    //})

    this.sketchObjSubscr = this.formData.controls['sketchObject'].valueChanges.subscribe(val => {
      if (val) {
        this.defectsListArray.controls.forEach((item, index) => {
          let currControl = <FormGroup>item
          currControl.controls['sketchId'].markAsDirty();
          currControl.controls['sketchId'].setValue(val.id)
        })
      } else {
        this.defectsListArray.controls.forEach((item, index) => {
          let currControl = <FormGroup>item
          currControl.controls['sketchId'].setValue(null)
        })
      }
    })
    this.allTypesObjSubscr = this.service.getDefType().subscribe(item => {
      this.allTypes = item;
      if (this.allTypes) {
        this.defectSubscription = this.fservice.castDefect.subscribe(def => {
          this.def = def;
     
          this.defectsListArray.clear();
          if (this.def && this.def.length !== 0) {
            this.mainInitialType = this.def[0].mainType
            this.initialType = this.def[0].defectType
            //this.loadQuantity = this.formData.controls['quantity'].value

            if (this.def[0].sketch && this.def[0].sketch.id !== 0) {
              this.sketches = [this.def[0].sketch]
             
              this.imgIndex = 0;

              this.membersNames = this.def[0].sketch
              this.selectedSketch = this.def[0].sketch;
              if (this.selectedSketch.name == 'General') {
                this.general = true;
              }
           
            } else {
              this.sketchSubscribtion = this.service.getSketch().subscribe(sketches => {
                this.allSketches = sketches;
                this.sketches = this.allSketches.filter(item => item.name !== 'General')
                this.generalSketch = this.allSketches.filter(item => item.name == 'General')[0]
                this.checkForGeneralImg();
              });
            }
          
           
            for (let i = 0; i < this.def.length; i++) {
              this.defectsListArray.push(this.defectFields())
              this.defectsListArray.controls[i].patchValue(this.def[i])
            }

            if (this.partObject && this.partObject.id != 0) {
              this.formData.controls['partObject'].setValue(this.partObject)
            } else {
              this.formData.controls['partObject'].setValue(null)
            }
            if (this.def[0].defectType) {
              this.subTypes = this.returnTypes(this.def[0].defectType.id);
            }         
            if (this.def[0].defectSubType) {
              this.subTypeSelected = this.def[0].defectSubType
            }
            if (this.def[0].ataChapter && this.def[0].ataChapter.id != 0) {
              this.formData.controls['ataObject'].setValue(this.def[0].ataChapter)
              this.getSections(this.def[0].ataChapter.chapter)
              this.selectedSection = {
                id: this.def[0].ataChapter.section,
                name: this.def[0].ataChapter.section
              }
            } else {
              this.formData.controls['ataObject'].setValue(null)
            }
            if (this.def[0].sketch && this.def[0].sketch.id != 0) {
              this.formData.controls['sketchObject'].setValue(this.def[0].sketch)
              this.checkForInletCowl(this.def[0].sketch)
            } else {
              this.formData.controls['sketchObject'].setValue(null)
              this.hidePosition = true;
            }
            this.defectsListArray.controls.forEach((item, index) => {
              let currControl = <FormGroup>item
              if (currControl.controls['defectType'].value) {
                currControl.controls['defectTypeId'].setValue(currControl.controls['defectType'].value.id)
              }
              if (currControl.controls['defectSubType'].value) {
                currControl.controls['defectSubTypeId'].setValue(currControl.controls['defectSubType'].value.id)
              }
            });
            this.defectsListArray.controls.forEach((item, index) => {
              let currControl = <FormGroup>item
              if (currControl.controls['defectType'].value) {
                currControl.controls['defectSubTypes'].setValue(this.returnTypes(currControl.controls['defectType'].value.id))
              }
            })
            this.defectsListArray.controls.forEach((item, index) => {
              let currControl = <FormGroup>item
              if (currControl.controls['part'].value && currControl.controls['part'].value.id!=0) {
                currControl.controls['partId'].setValue(currControl.controls['part'].value.id)
              }
            })
          }
        });
      }
    });
  }

  ngOnDestroy(): void {
    this.nriObjSubscr.unsubscribe();
    this.destroy.next();
    this.destroy.complete();
    if (this.sketchObjSubscr) {
      this.sketchObjSubscr.unsubscribe();
    }
    if (this.ataByPartSubscr) {
      this.ataByPartSubscr.unsubscribe();
    }
    if (this.defectSubscription) {
      this.defectSubscription.unsubscribe();
    }
   
    if (this.sectionsSubscription) {
      this.sectionsSubscription.unsubscribe();
    }
    if (this.ataSubscription) {
      this.ataSubscription.unsubscribe();
    }
    //this.partObjectSubscr.unsubscribe();
    this.ataObjectSubscr.unsubscribe();
    if (this.sketchSubscribtion) {
      this.sketchSubscribtion.unsubscribe();
    }
    this.allTypesObjSubscr.unsubscribe();
  }
}
