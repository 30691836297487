import { Component, ElementRef, HostListener } from '@angular/core';
import { AdalService } from 'adal-angular4';
import { AuthGuardService } from '../auth-guard.service';
import { Subscription } from 'rxjs';
import { NonRoutineItemService } from '../nonRoutineItem.service';
import { FormsService } from '../forms.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
})
export class HeaderComponent {
  constructor(private _eref: ElementRef,private adal: AdalService, private service: NonRoutineItemService, private guard: AuthGuardService, private fservice: FormsService) { }
  username: string;
  email: string;
  name: string;
  show: boolean = false;
  userSubscription: Subscription;
  currUserSubscription: Subscription;
  admin: boolean ;
  user;

  showDropdown() {
    this.show = !this.show;
  }
  logOut() {
    let storage = window.localStorage
    storage.clear();
    this.adal.logOut();
  }
  ngOnInit() {
 


    if (!this.adal.userInfo.authenticated) {
      this.guard.updateUser('');

    }
   
    this.currUserSubscription = this.guard.cast.subscribe(result => {
      this.user = result
     
      if (this.user) {
        this.username = this.adal.userInfo.userName.split('@')[0]
        this.name = this.adal.userInfo.userName.split('@')[0]
        this.email = this.adal.userInfo.userName
        this.userSubscription = this.service.getCurrentUser(this.email).subscribe(data => {
          if (data && data[0]) {
            this.fservice.updateUser(data);
            if (data[0].defManAdmin) {
              this.admin = true;
            } else {
              this.admin = false;
            }
          }
          
         

        })
      }


    })

   
   


  
  }


  ngOnDestroy() {
    this.userSubscription.unsubscribe();
    this.currUserSubscription.unsubscribe();
  }
  @HostListener('window:click', ['$event']) onClick(e) {
    const target = e.target;


    if (!this._eref.nativeElement.contains(event.target) && this.show) {
      this.show = false;
    }



  }

}
