// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.
let mainUrl = window.location.origin;

export const environment = {
  production: false,
  server: 'http://localhost:54653/',
  adalConfig: {
    tenant: '1e699703-f2ca-4bf1-9057-eb673861d4d6',
    clientId: '1103419b-5685-4339-84d6-86ba8699259c',
    redirectUri: mainUrl + '/auth-callback',
    postLogoutRedirectUri: mainUrl + '/logout',
    endpoints: {
      'WEB_API_URL': mainUrl
    }
  }
 
}
