import { Component, Input, Output, EventEmitter, ViewChild, ElementRef  } from '@angular/core';
import { Parts,DefectInfo } from '../../models';
import { FormsService } from '../../forms.service'
import { FormControl, FormArray } from '@angular/forms';
import {  Subscription } from 'rxjs';
@Component({
  selector: 'app-part-info',
  templateUrl: './part-info.component.html',
})
export class PartInfoComponent {
  constructor(private service: FormsService) { }
  @ViewChild('quantityInput', { static: false }) public quantity: ElementRef ;
  @Input() formData?: FormArray;
  @Input() part: Parts;
  @Input() loadPart;
 @Input() disabled;
  @Input() quantityLoaded;
  @Output() quantityValue: EventEmitter<number> = new EventEmitter<number>();
  quantitySubsc: Subscription;
 
  defectsQuantity;

  increaseQuantity(e) {
    e.preventDefault();
    this.quantity.nativeElement.value++
    this.quantityValue.emit(Number(this.quantity.nativeElement.value))
 
  }
  decreaseQuantity(e) {
    e.preventDefault();
    if (this.quantity.nativeElement.value > 0) {
      this.quantity.nativeElement.value--
      this.quantityValue.emit(Number(this.quantity.nativeElement.value))
    }
   
    
  }
  ngOnInit() {
    //this.quantity = this.loadPart.quantity
    //if (this.formData) {
    //  this.quantitySubsc = this.formData.controls['quantity'].valueChanges.subscribe(
    //    quantity => {

    //    });
    //}
  
  }
  ngOnDestroy() {
    if (this.quantitySubsc) {
      this.quantitySubsc.unsubscribe();
    }
    
  }

}
