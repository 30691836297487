import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NonRoutineItemService } from '../nonRoutineItem.service';
import { FormsService } from '../forms.service';
import { NonRoutineItemShort } from '../models';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-home-container',
  templateUrl: './home.container.html'
})
export class HomeContainer implements OnInit {
  constructor(public router: Router, private NRIservice: NonRoutineItemService, private fservice: FormsService) { }
  showSpinner: boolean = true;
  workItemsSubscription: Subscription;
  userSubscription: Subscription;
  user;
  userStations = [];

  workItems
  workItems$:    Observable<NonRoutineItemShort[]> = this.NRIservice.getShortNonRoutineItem();
  public filterOptions = [{name: 'My Station', value: 1, radioGroup: 'filterOptions'},{ name: 'My Items', value: 2, radioGroup: 'filterOptions' }];
  allItems;
  clearBehaviorSubject() {
    this.fservice.updateType(null);
    this.fservice.updateInvolvedWC(null);
    this.fservice.updateNewID(null);
    this.fservice.updateStepsArray([]);
    this.fservice.updateDefPanel([]);
    this.fservice.updateDefectID(null);
    this.fservice.updateDefect([]);
    this.fservice.updateIsNew(false);
    this.fservice.updatePartName('');
    this.fservice.updateChapter(null);
    this.fservice.updateUser(null);
    this.fservice.updateNriObject(null);
    this.fservice.updateWorkStations([]);
  }


 arrayUnique(array) {
  var a = array.concat();
  for (var i = 0; i < a.length; ++i) {
    for (var j = i + 1; j < a.length; ++j) {
      if (a[i] === a[j])
        a.splice(j--, 1);
    }
  }

  return a;
}



  filterNRI(filterId: any) {
   

    if (filterId == 1) {
      this.workItems = [];
      let filteredArr = [];
   

      for (let i = 0; i < this.userStations.length; i++) {
   
        filteredArr = this.allItems.filter(item => {
          if (item.leadingWorkStation) {

            return item.leadingWorkStation == this.userStations[i] || item.involvedWorkStation.includes(this.userStations[i]) 
          }

        })
        this.workItems = this.arrayUnique(this.workItems.concat(filteredArr))

      }

    } if (filterId == 2) {
      if (this.user[0]) {
       
        this.workItems = this.allItems.filter(item => {
          if (item.owner) {
            return item.owner.id == this.user[0].id
          }
         
        }
         
        )}
      
    }
    if (filterId == 3) {
      
        this.workItems = this.allItems
        
    }

  }
  ngOnInit() {
    this.clearBehaviorSubject();
    this.userSubscription = this.fservice.castCurrentUser.subscribe(user => {
      this.user = user;
      if (this.user) {
        this.workItemsSubscription = this.NRIservice.getShortNonRoutineItemFiltered(this.user[0].id).subscribe(result => {
          this.allItems = result
          this.workItems = result
          this.filterNRI(3)
          this.showSpinner = false;
        })


        this.user[0].userWorkStation.forEach(item => {
          this.userStations.push(item.workStation.name)
        })
      }
    });
    
   
  }
  ngOnDestroy() {
    if (this.workItemsSubscription) {
      this.workItemsSubscription.unsubscribe();
    }
  
    this.userSubscription.unsubscribe();
  }
}
