import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { OverviewComponent } from './overview/overview.component';
import { OverviewContainer} from './overview/overview.container';
import { RectificationComponent } from './rectification/rectification.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { PartInfoComponent } from './common/part-info/part-info.component';
import { StepContainer } from './steps/steps.container';
import {  StepComponent } from './steps/steps.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {NotificationComponent } from '../app/common/notifications/notification.component';
import { TabGroup } from './nav-menu/tabs/tab-group.component';
import { LayoverManagementComponent } from '../app/admin/layover-management.component';
import { LayoverFormComponent } from '../app/admin/layover-form.component';
import { CreateUserComponent } from '../app/admin/create-user.component';
import { PeoplePickerComponent } from '../app/common/peoplePicker/people-picker.component';
import { AdminPanelComponent } from '../app/admin/admin-panel.component'; 
import { HomeComponent } from './home/home.component';
import { HomeContainer } from './home/home.container'

import { RadioNgModelExample } from './common/radioInput/radio-ng-model';
import { AutocompleteComponent } from './common/autocomplete/autocomplete.component';




import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { SelectComponent } from './common/dropdown/select.component';
import { CheckboxComponent } from './common/checkbox/checkbox.component';
import {NewJCComponent } from '../app/common/dialogs/newJC.component';
import { DialogDeleteComponent } from './common/dialogs/dialogs.component';
import {AppMaterialModule } from './app-material.module';
import { RectificationContainer } from './rectification/rectification.container';
import { LoginComponent } from '../app/login/login.component';
import { FormsService } from '../app/forms.service';
import { NonRoutineItemService } from '../app/nonRoutineItem.service';
import { AppRoutingModule } from '../app/app-routing.module';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthCallbackComponent } from '../app/authcallback/authcallback';
import { AdalService, AdalInterceptor } from 'adal-angular4';
import { AuthGuardService as AdalGuard } from '../app/auth-guard.service';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HtmlTemplatesService} from '../app/rectification/html-templates.service';
import { WhatWhereComponent } from '../app/what-where-2/what-where.component';
import { WhatWhereContainer } from '../app/what-where-2/what-where.container';
import { LogoutComponent } from '../app/logout/logout';

import { SpinnerComponent } from '../app/common/spinner/spinner';
import { UserPreferencesComponent } from '../app/preferences/user-preferences';


import { NewTicketComponent } from '../app/common/dialogs/new-ticket.component';

@NgModule({
  declarations: [
    AppComponent,
    LayoverManagementComponent,
    AdminPanelComponent,
    AuthCallbackComponent,
    HomeComponent,
    PeoplePickerComponent,
    HeaderComponent,
    HomeContainer,
    LoginComponent,
    CheckboxComponent,
    OverviewComponent,
    LayoverFormComponent,
    OverviewContainer,
    RectificationComponent,
    RectificationContainer,

    PartInfoComponent,
    LogoutComponent,
    CreateUserComponent,
    //WhatWhereContainer,
    //WhatWhereComponent,
    NewTicketComponent,
    WhatWhereContainer,
    WhatWhereComponent,
    SelectComponent,
 
    TabGroup,
    SpinnerComponent,
    RadioNgModelExample,
    StepComponent,
    NotificationComponent,
    StepContainer,
    AutocompleteComponent,
    DialogDeleteComponent,
    UserPreferencesComponent,
    NewJCComponent
  ],
  entryComponents: [
    DialogDeleteComponent,
    NewTicketComponent,
    NewJCComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    ScrollingModule,
    AppMaterialModule,
 
    NgbModule,

    BrowserAnimationsModule,
  
  
    FormsModule,
    ReactiveFormsModule,

    AppRoutingModule



  ],
  providers: [FormsService, NonRoutineItemService, AdalService, HtmlTemplatesService, AdalGuard, { provide: HTTP_INTERCEPTORS, useClass: AdalInterceptor, multi: true }

  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
