import { Component } from '@angular/core';
import { AdalService } from 'adal-angular4';
import { Router } from '@angular/router';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'

})
export class AppComponent{
  mainUrl = window.location.origin;
  title = 'app';
 

  adalConfig= {
    tenant: '1e699703-f2ca-4bf1-9057-eb673861d4d6',
    clientId: '1103419b-5685-4339-84d6-86ba8699259c',
    redirectUri:
      this.mainUrl + '/auth-callback',
      postLogoutRedirectUri: this.mainUrl + '/logout'
//        endpoints: {
//  'WEB_API_URL': this.mainUrl
//}
  }


  constructor(private adal: AdalService) {
    this.adal.init(this.adalConfig);
  }
  ngOnInit() {

  }
  ngOnDestroy() {
    let storage = window.localStorage
    storage.clear();
  }
}

