import { Component, Inject, Input, EventEmitter, Output, ComponentRef, ViewChild} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DialogDeleteComponent } from '../common/dialogs/dialogs.component';
import { Observable, Subject, Subscription } from 'rxjs';
import { FormsService } from '../forms.service';
import { AdditionalStep } from '../models';
import { NonRoutineItemService } from '../nonRoutineItem.service';
import { Parts } from '../models'
import { FormGroup, NgForm, FormBuilder, FormArray, FormControl } from '@angular/forms';

export interface removeInterface {
  remove(index: number);
}

@Component({
  selector: 'steps-component',
  templateUrl: 'steps.component.html'

})

export class StepComponent {
  @ViewChild('f', { static: false }) public form: NgForm;
  @Output() onSubmit = new EventEmitter<any>();
  @Output() selectedVal = new EventEmitter<object>();
  @Output() createStep = new EventEmitter<boolean>();
  @Output() createJobcard = new EventEmitter<boolean>();
  @Output() deleteDialog = new EventEmitter<any>();
  @Input() stations;
  @Input() refType;
  @Input() stepsListArray;
  @Input() stepFields;
  @Input() stepType
  @Input() formData: FormGroup;
  @Input() disableFormFields;

  @Input() fail: boolean;
  @Input() hasChanges: boolean;

  public index: number;
  public whatOptions = [
    { name: 'Component', value: 1, id: 1 },
    { name: 'Panel', value: 2, id: 2 },
    { name: 'Zone', value: 3, id: 3 },
    { name: 'Wiring', value: 4, id: 4 }

  ];
  showItem: boolean = false;



  constructor(private service: NonRoutineItemService, public dialog: MatDialog, private fservice: FormsService, private fb: FormBuilder) {


  }


  selectZone(val, fgroup: FormGroup) {
    fgroup.controls['zoneId'].markAsDirty();
    fgroup.controls['zoneId'].setValue(Number(val.id))
    fgroup.controls['zone'].setValue(val)
    this.markId(fgroup);
    
   
  }
  getQuantity(val, fgroup: FormGroup) {
    fgroup.controls['quantity'].markAsDirty();
    fgroup.controls['quantity'].setValue(val)
    this.markId(fgroup);
  }


  rectType(message, fgroup: FormGroup) {
   
    fgroup.controls['referenceTypeId'].markAsDirty();
    fgroup.controls['referenceTypeId'].setValue(message.id)
    fgroup.controls['referenceType'].setValue(message)
    if (message.name === 'IPC') {
      fgroup.controls['item'].setValue('')
    } else {
      fgroup.controls['item'].setValue(null)
    }
    this.markId(fgroup);
   
  }
  createJC(e) {
    e.preventDefault();

    this.createJobcard.emit(true)

   
      
  }

  selectAction(result, fgroup: FormGroup) {
   
    fgroup.controls['actionTypeId'].markAsDirty();
    fgroup.controls['actionTypeId'].setValue(result.id)
    fgroup.controls['actionType'].setValue(result)
    this.markId(fgroup);
  

  }
  markId(fgroup: FormGroup) {
    fgroup.controls['id'].markAsDirty();
  }
  chosenVal(message, fgroup: FormGroup) {
 
    fgroup.controls['part'].setValue(null)
    fgroup.controls['partId'].setValue(null)
    fgroup.controls['panel'].setValue(null)
    fgroup.controls['wiring'].setValue(null)
    fgroup.controls['zone'].setValue(null)
    fgroup.controls['zoneId'].setValue(null)
    fgroup.controls['quantity'].setValue(null)
    this.selectedVal.emit({ what: message, fg: fgroup })
  }
  openDialog(e, i, id) {
    e.preventDefault();
    this.deleteDialog.emit({ index: i, id: id });
    //const dialogRef = this.dialog.open(DialogDeleteComponent, {
    //  width: '350px',

    //});


    //dialogRef.afterClosed().subscribe(result => {
    //  if (result) {

    //    this.stepsListArray.removeAt(i)
    //    this.service.deleteStep(Number(id)).subscribe();
    //    this.stepsListArray.controls.forEach((item, index) => {
    //      this.hasChanges = false;
    //      let currControl = <FormGroup>item
    //      if (!currControl.controls['taskId'].value) {
    //        this.hasChanges = true;
    //      }
    //    });
    //  }
    //});
  }

 

  selectWC(result, fgroup: FormGroup) {
    fgroup.controls['workStationId'].markAsDirty();
    fgroup.controls['workStationId'].setValue(result.id)
    fgroup.controls['workStation'].setValue(result)
    this.markId(fgroup);

  }
  partChosen(message, fgroup: FormGroup) {
    fgroup.controls['partId'].markAsDirty();
    fgroup.controls['partId'].setValue(message.id)
    fgroup.controls['part'].setValue(message)
    fgroup.controls['quantity'].setValue(1)
    this.markId(fgroup);
  
   
  }



  addStep() {
    this.createStep.emit(true);
   
  }

  ngOnInit() {
   
  }
 
  ngOnDestroy() {
  
  }
}


