import { Router } from '@angular/router';
import { AdalService } from 'adal-angular4';
import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'app-logout-component',
  template: '<div>logged out</div>'
})

export class LogoutComponent implements OnInit {

  constructor(private router: Router, private adalService: AdalService) { }

  ngOnInit() {
   
  }

  
}
