import { FormControl } from '@angular/forms';
import { Component, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import {  Subscription } from 'rxjs';
import { Parts } from '../../models';
import { NonRoutineItemService } from '../../nonRoutineItem.service';
import { FormsService } from '../../forms.service';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
@Component({
  selector: 'autocomplete-component',
  templateUrl: 'autocomplete.component.html'

})
export class AutocompleteComponent {
  @ViewChild(MatAutocompleteTrigger, { static: false }) autocomplete: MatAutocompleteTrigger;

  private _partName: string;
  private _zone;
  private _actionType;
  private _chapter;
  private _aircraft;
  private _layover;
  private _disabled;
  showLoader: boolean = false;
  showPanel: boolean = false;

  allParts;
  filtered;
  allZones;
  filteredZones;
  allActionTypes;
  filteredActionTypes;
  allAircrafts;
  filteredAircrafts;
  allLayovers;
  filteredLayovers;


  options;

  partsSubsc: Subscription;
  actionsSubsc: Subscription;
  ataSubscription: Subscription;
  zonesSubsc: Subscription;
  layoverSubscr: Subscription;
  aircraftSubscr: Subscription;
  color = 'primary';
  mode = 'indeterminate';
  value = 2;
  //disable

  @Input() set disabled(val: boolean) {
    this._disabled = val;
    if (this._disabled) {
      this.chapterControl.disable();
      this.partControl.disable();
      this.zoneControl.disable();
      this.actionTypeControl.disable();
      this.aircraftControl.disable();
      this.layoverControl.disable();
    } else {
      this.chapterControl.enable();
      this.partControl.enable();
      this.zoneControl.enable();
      this.actionTypeControl.enable();
      this.aircraftControl.enable();
      this.layoverControl.enable();
    }
  }
  get disabled(): boolean {
    return this._disabled
  }

  //aircraft

  @Input() set layover(val: string) {
    this._layover = val;
    this.layoverControl.setValue(this._layover)
  }
  get layover(): string {
    return this._layover
  }

  //aircraft

  @Input() set aircraft(val: string) {
    this._aircraft = val;
    this.aircraftControl.setValue(this._aircraft)
  }
  get aircraft(): string {
    return this._aircraft
  }


  //actionTypes

  
  @Input() set actionType(val: string) {
    this._actionType = val;
    this.actionTypeControl.setValue(this._actionType)
  }
  get actionType(): string {
    return this._actionType
  }
  //parts
  @Input() set partName(val: string) {
    this._partName = val;
    this.partControl.setValue(this._partName)
  }
  get partName(): string {
    return this._partName
  }
  //chapter
  @Input() set chapter(val: string) {
    this._chapter = val;
    this.chapterControl.setValue(this._chapter)
  }
  get chapter(): string {
    return this._chapter
  }
  //zones

  @Input() set zone(val: string) {
    this._zone = val;
    this.zoneControl.setValue(this._zone)
  }
  get zone(): string {
    return this._zone
  }

  @Input() autocompleteType;

  @Output() partChosen: EventEmitter<Parts> = new EventEmitter();
  @Output() selectChapter = new EventEmitter<string>();
  @Output() selectZone = new EventEmitter<string>();
  @Output() selectAction = new EventEmitter<any>();
  @Output() selectAircraft = new EventEmitter<any>();
  @Output() selectLayover = new EventEmitter<any>();

  chapterControl = new FormControl();
  partControl = new FormControl();
  zoneControl = new FormControl();
  actionTypeControl = new FormControl();
  aircraftControl = new FormControl();
  layoverControl = new FormControl();
  constructor(private service: NonRoutineItemService, private fservice: FormsService) { }

  displayFnAircraft(aircraft?): string | undefined {
    return (aircraft && aircraft.registration) ? aircraft.registration : aircraft;
  }
  displayFnPart(part?): string | undefined {
      return (part && part.name) ? part.name : part;
  }
  displayFnChapter(ata?): string | undefined {
    return (ata && ata.chapter) ? ata.chapter : ata;
  }
  displayFnAction(action?): string | undefined {
    if (action) {
      return action.name ? action.abbreviation + ' - ' + action.name : action;
    }
  } 
  displayFnZone(zone?): string | undefined {
   
      return (zone && zone.zoneName) ? zone.zoneID + ' - ' + zone.zoneName + ' - ' + zone.handPositioning : zone;
   
  }
  displayFnLayover(layover?): string | undefined {

    return (layover && layover.salesOrder) ? layover.salesOrder : layover;

  }

  aircraftType() {
    this.showLoader = true;
    if (!this.aircraftSubscr) {
      if (this.aircraftControl.value.length >= 3) {

        this.aircraftSubscr = this.service.getAircraft(this.aircraftControl.value).subscribe(item => {

          this.allAircrafts = item
          this.filterAircrafts(this.aircraftControl.value.toLowerCase())
        })

      }

    } else {
      if (this.aircraftControl.value.length == 3) {

        this.aircraftSubscr = this.service.getAircraft(this.aircraftControl.value).subscribe(item => {

          this.allAircrafts = item
          this.filterAircrafts(this.aircraftControl.value.toLowerCase())
        })
      }
      if (this.aircraftControl.value.length > 3) {

        this.filterAircrafts(this.aircraftControl.value.toLowerCase())

      }
      if (this.aircraftControl.value.length < 3) {
        this.showLoader = false;
        this.autocomplete.closePanel();
      }

    }

  }
layoverType() {
    this.showLoader = true;
  if (!this.layoverSubscr) {
      if (this.layoverControl.value.length >= 3) {
      
        this.layoverSubscr = this.service.getEvent(this.layoverControl.value).subscribe(item => {
          
          this.allLayovers = item
          this.filterLayovers(this.layoverControl.value.toLowerCase())
        })

      }

    } else {
      if (this.layoverControl.value.length == 3) {
       
        this.layoverSubscr = this.service.getEvent(this.layoverControl.value).subscribe(item => {

          this.allLayovers = item
          this.filterLayovers(this.layoverControl.value.toLowerCase())
        })
      }
      if (this.layoverControl.value.length > 3) {

        this.filterLayovers(this.layoverControl.value.toLowerCase())

      }
      if (this.layoverControl.value.length < 3) {
        this.showLoader = false;
        this.autocomplete.closePanel();
      }

    }
    
  }
  partsAType() {
    this.showLoader = true;
    if (!this.partsSubsc) {
      if (this.partControl.value.length >= 3) {
        this.partsSubsc = this.service.getPart(this.partControl.value).subscribe(item => {
        
          this.allParts = item
          this.filterParts(this.partControl.value.toLowerCase())
        })

      }

    } else {
      if (this.partControl.value.length == 3) {
        this.partsSubsc = this.service.getPart(this.partControl.value).subscribe(item => {
       
          this.allParts = item
          this.filterParts(this.partControl.value.toLowerCase())
        })
      }
      if (this.partControl.value.length > 3) {
    
        this.filterParts(this.partControl.value.toLowerCase())

      }
      if (this.partControl.value.length < 3) {
        this.showLoader = false;
        this.autocomplete.closePanel();
      }

    }
  }

  stepsAType() {
    this.showLoader = true;
    if (!this.actionsSubsc) {
      if (this.actionTypeControl.value.length >= 3) {
        this.actionsSubsc = this.service.getRectificationActionsTypes(this.actionTypeControl.value).subscribe(item => {
          this.allActionTypes = item
          this.filterActionType(this.actionTypeControl.value.toLowerCase())
        })

      }

    } else {
      if (this.actionTypeControl.value.length == 3) {
        this.actionsSubsc = this.service.getRectificationActionsTypes(this.actionTypeControl.value).subscribe(item => {
          this.allActionTypes = item
          this.filterActionType(this.actionTypeControl.value.toLowerCase())
        })
      } if (this.actionTypeControl.value.length > 3) {
        this.filterActionType(this.actionTypeControl.value.toLowerCase())

      }
      if (this.actionTypeControl.value.length < 3) {
        this.showLoader = false;
        this.autocomplete.closePanel();
      }
    }
  }

  ataAType() {
    this.showLoader = true;
    if (this.chapterControl.value.length == 2) {
      let ataChapterInput = this.chapterControl.value + '/00';
      this.ataSubscription = this.service.getSketchByAtaChapter(ataChapterInput).subscribe(result => {
        this.options = result;
        this.showLoader = false;
      });
    }
    if (this.chapterControl.value.length!=2) {
      this.showLoader = false;
      this.autocomplete.closePanel();
    }
  }

  zonesAType() {
    this.showLoader = true;
    if (!this.zonesSubsc) {
      if (this.zoneControl.value.length >= 3) {
        this.zonesSubsc = this.service.getZone(this.zoneControl.value).subscribe(item => {
          this.allZones = item
          this.filterStepZone(this.zoneControl.value.toLowerCase())
        })

      }
      if (this.zoneControl.value.length == 0) {
        this.showLoader = false;
      }
    } else {
      if (this.zoneControl.value.length == 3) {
        this.zonesSubsc = this.service.getZone(this.zoneControl.value).subscribe(item => {
          this.allZones = item
          this.filterStepZone(this.zoneControl.value.toLowerCase())
        })
      } if (this.zoneControl.value.length > 3) {
        this.filterStepZone(this.zoneControl.value.toLowerCase())

      }
      if (this.zoneControl.value.length < 3) {
        this.showLoader = false;
        this.autocomplete.closePanel();
      }
    }
  }
  filterAircrafts(inputVal) {
    if (this.allAircrafts) {
      this.filteredAircrafts = this.allAircrafts.filter(option =>
        option.registration.toLowerCase().includes(inputVal)
      )
      this.showLoader = false;
    }
    
  }
  filterLayovers(inputVal) {
    if (this.allLayovers) {
      this.filteredLayovers = this.allLayovers.filter(option =>
        option.salesOrder.toLowerCase().includes(inputVal) ||
        option.aircraft.registration.toLowerCase().includes(inputVal)
      )
      this.showLoader = false;
    }
   
  }
  filterParts(inputVal) {
    if (this.allParts) {
      this.filtered = this.allParts.filter(option =>
        option.number.toLowerCase().includes(inputVal) ||
        option.name.toLowerCase().includes(inputVal) ||
        option.fin.toLowerCase().includes(inputVal)
      )
      this.showLoader = false;
    }
   
   
  }
  filterStepZone(inputVal) {
    if (this.allZones) {
      this.filteredZones = this.allZones.filter(option =>
        option.zoneName.toLowerCase().includes(inputVal) ||
        option.zoneID.toLowerCase().includes(inputVal)
      )
      this.showLoader = false;
    }
   
  }
  filterActionType(inputVal) {
    if (this.allActionTypes) {

      this.filteredActionTypes = this.allActionTypes.filter(option =>
        option.name.toLowerCase().includes(inputVal) ||
        option.abbreviation.toLowerCase().includes(inputVal)
      )
      this.showLoader = false;
    }
 
  }

  closeLoader(e) {
    this.showLoader = false;
  }
  ngOnInit() {
  


  }
  ngOnDestroy() {
    if (this.partsSubsc) {
      this.partsSubsc.unsubscribe();
    }
    if (this.ataSubscription) {
      this.ataSubscription.unsubscribe();
    }
    if (this.actionsSubsc) {
      this.actionsSubsc.unsubscribe();
    }
    if (this.zonesSubsc) {
      this.zonesSubsc.unsubscribe();
    }
  }

}
