
import { Component, OnInit, Output,Input, EventEmitter } from '@angular/core';

@Component({
  selector: 'notification-component',
  templateUrl: 'notification.component.html'
})

export class NotificationComponent {
  @Input() alertMessage;
  @Input() isSuccess: boolean;
  @Output() closeAlert: EventEmitter<boolean> = new EventEmitter<boolean>();


  ngOnInit() {
    setTimeout(() => {
      this.alertMessage = '';
      this.closeAlert.emit(false);
      
    }, 3000);
  }
}
