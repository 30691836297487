import { Component, ViewChild, OnDestroy,OnInit} from '@angular/core';
import { FormsService } from '../../forms.service';
import { OverviewContainer } from '../../overview/overview.container';
import { Subscription } from 'rxjs';
import { NonRoutineItemService } from '../../nonRoutineItem.service';
import { NgbTabChangeEvent } from '@ng-bootstrap/ng-bootstrap'
import { Router } from '@angular/router';
import { RectificationContainer } from '../../rectification/rectification.container';
import { WhatWhereContainer } from '../../what-where-2/what-where.container';
import { StepContainer } from '../../steps/steps.container';
import { Notification } from '../../models';
@Component({
  selector: 'tab-group',
  templateUrl: 'tab-group.component.html'
})
export class TabGroup implements OnInit, OnDestroy {
  @ViewChild(OverviewContainer, { static: false }) overview: OverviewContainer;
  @ViewChild(WhatWhereContainer, { static: false }) whatWhere: WhatWhereContainer;
  @ViewChild(RectificationContainer, { static: false }) rectification: RectificationContainer;
  @ViewChild(StepContainer, { static: false }) steps: StepContainer;
  private idSubscribtion: Subscription;
  private isNewSubscribtion: Subscription;
  private typeSubscribtion: Subscription;
  private defectSubscribtion: Subscription;

  private ownerSubscribtion: Subscription;
  private userSubscription: Subscription;
  currUser;
  owner;
  private nriId: number;
  private type;
  alertMessage: string;
  showNotification: boolean;
  isSuccess: boolean;
  defect;
  isNew: boolean = false;
  disableFormFields: boolean;

  constructor(private fservice: FormsService, public router: Router, private apiService: NonRoutineItemService) { }
  isSuccessUpdate(message: Notification) {
    if (!message.isSuccess) {
          this.alertMessage = message.message;
          this.isSuccess = false;
          this.showNotification = true;
    } else {
          this.alertMessage = message.message;
          this.isSuccess = true;
          this.showNotification = true;
    }
  }
  onTabChange(event: NgbTabChangeEvent) {

    if (event.activeId == 'overview' && this.isNew) {
     
      this.overview.children.form.ngSubmit.emit();
      if (!this.overview.children.form.valid) {
        event.preventDefault();
      } 

    }
    if (event.activeId == 'overview' && this.router.url == '/view') {
      
    }
    if (event.nextId == 'overview' && this.router.url.indexOf('create')>0) {
     this.router.navigate(['view/' + this.nriId])
    }
  
    if (event.activeId == 'whatWhere') {
      if (this.whatWhere.children.form.dirty) {
        this.whatWhere.children.form.ngSubmit.emit();
        if (this.whatWhere.children.form.form.controls['partObject'].dirty || this.whatWhere.children.form.form.controls['quantity'].dirty) {
          if (this.whatWhere.children.form.form.controls['partObject'].dirty) {
            this.overview.children.formData.controls['PartId'].setValue(this.whatWhere.children.form.form.controls['partObject'].value.id)
            this.overview.children.formData.controls['PartId'].markAsDirty();
          }
          if (this.whatWhere.children.form.form.controls['quantity'].dirty) {
            this.overview.children.formData.controls['Quantity'].setValue(this.whatWhere.children.form.form.controls['quantity'].value)
            this.overview.children.formData.controls['Quantity'].markAsDirty();

          }
          this.overview.children.saveChanges();
        }
       
        
       // this.updateDefectData();
      }
      
    }
  
    if (event.activeId == 'rectification') {
      this.rectification.children.form.ngSubmit.emit();

    }
    if (event.activeId == 'steps') {
      this.steps.children.form.ngSubmit.emit();

    }


    if (event.nextId == 'whatWhere') {
      if (this.whatWhere.children.form.dirty) {
        this.whatWhere.children.form.form.markAsPristine()
      }

    }
    if (event.nextId == 'rectification') {
      if (this.rectification.children.form.dirty) {
        this.rectification.children.form.form.markAsPristine();
      }

    }
    if (event.nextId == 'steps') {
      if (this.steps.children.form.dirty) {
        this.steps.children.form.form.markAsPristine();
      }
     

    }
  }
  updateDefectData() {
    this.defectSubscribtion = this.apiService.getDefect(Number(this.nriId)).subscribe(defect => {

      this.defect = defect;

      if (this.defect) {
        this.fservice.updateDefect(this.defect);

      }
    });
  }

  ngOnInit() {
   
    this.userSubscription = this.fservice.castCurrentUser.subscribe(result => {
      if (result) {
        this.currUser = result[0];
        this.ownerSubscribtion = this.fservice.castOwner.subscribe(owner => {
          if (owner) {
            this.owner = owner
     
            if (this.currUser.id != this.owner.id) {
              this.disableFormFields = true;
            } else {
              this.disableFormFields = false;
            }
          }

        })
      }
    })
    
    this.isNewSubscribtion = this.fservice.castIsNew.subscribe(isNew => {
      this.isNew = isNew;
    })
    this.typeSubscribtion=this.fservice.cast.subscribe(type => {
    
      this.type=type
    })
    this.idSubscribtion=this.fservice.castID.subscribe(nriId => {
    this.nriId = nriId
      if (this.nriId) {
        this.updateDefectData();
      }
    });  
  }
  ngOnDestroy() {
    this.typeSubscribtion.unsubscribe();
    this.idSubscribtion.unsubscribe();
    if (this.defectSubscribtion) {
      this.defectSubscribtion.unsubscribe();
    }
    this.isNewSubscribtion.unsubscribe();
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
    if (this.ownerSubscribtion) {
      this.ownerSubscribtion.unsubscribe();
    }
  }
 
}
