import { Injectable } from '@angular/core';



@Injectable()
export class HtmlTemplatesService {


  createDefTempl(defect, index) {
    let defectTempl = `
<h3 class="inline-heading">Defect `+ index + `</h3><span class="glyphicon glyphicon-chevron-down"></span>
<div class="def-body">
  <div class="def-type">
    <label class="underlined">Type:</label>
    <div>`+ this.typeTemplate(defect) + `</div>
  </div>
  <table>
        <tr>`+ this.returnExistingRepair(defect) + `</tr>
        <tr>`+ this.returnInternalStr(defect) + `</tr>
  </table>

`+
     
        this.createDefPartTempl(defect)
     
      


      +`

  <label class="underlined">Positions:</label>
`+ this.positioningTemplate(defect) + ``
    if (defect.fromExtra || defect.fromExtraValue) {
      let templAdditional = `
  <label class="underlined">Additional positioning:</label>
    <table>
        <tr>
            <td><label>From </label> <span>`+ this.checkNulls(defect.fromExtra) + `</span></td>
            <td><label>Value </label> <span>`+ this.checkNulls(defect.fromExtraValue) + `</span> mm</td>

        </tr>

    </table>
`
      defectTempl = defectTempl + templAdditional
    }


    if (defect.repairProposal && defect.repairProposal != "") {
      let templProp = `
  <label class="underlined repair">Repair proposal:</label>
 <div class="proposal">
`+ defect.repairProposal + `
</div>
`
      defectTempl = defectTempl + templProp
    }

    if (defect.defectDimensions && defect.defectDimensions.length > 0) {
      let dimTempl = `
<label class="underlined">Dimensions in mm:</label>
`+ this.dimensionsTempl(defect) + `
`
      defectTempl = defectTempl + dimTempl

    }
    if (defect.mainType && defect.mainType == "STR") {
      let templ = ` <div>`+ this.strSpesificTempl(defect) + `</div>`
      defectTempl = defectTempl + templ
    }

   









   

    return defectTempl + `</div>`
  }
//  positioningTemplate(defect){

//}
 
  returnDimensionVal(dimensions, vals) {
    let val;
    if (vals) {
      vals.forEach(item => {
        if (item.dimensionId == dimensions.id) {
          val = item.value
        }
      })
    }
    if (val) {

      return val

    } else {
      return ''
    }
  }

  dimensionsTempl(defect) {
    let firstDimension = ``;
    let secondDimension = ``;
    let thirdDimension = ``;
    let fourthDimension = ``;
    if (defect.defectType.firstDimension.name) {
      firstDimension = `
<td>
<label>`+ defect.defectType.firstDimension.name + `</label> : <span>` + this.returnDimensionVal(defect.defectType.firstDimension, defect.defectDimensions) + `</span>
</td>
`
    }
    if (defect.defectType.secondDimension.name) {
      secondDimension = `
<td>
<label>`+ defect.defectType.secondDimension.name + `</label> : <span>` + this.returnDimensionVal(defect.defectType.secondDimension, defect.defectDimensions) + `</span>
</td>
`
    }
    if (defect.defectType.thirdDimension.name) {
      thirdDimension = `
<td>
<label>`+ defect.defectType.thirdDimension.name + `</label> : <span>` + this.returnDimensionVal(defect.defectType.thirdDimension, defect.defectDimensions) + `</span>
</td>
`
    }
    if (defect.defectType.fourthDimension.name) {
      fourthDimension = `
<td>
<label>`+ defect.defectType.fourthDimension.name + `</label> : <span>` + this.returnDimensionVal(defect.defectType.fourthDimension, defect.defectDimensions) + `</span>
</td>
`
    }
    let dimensions = `
<table>
<tr>
`+ firstDimension + ` ` + secondDimension + ` ` + thirdDimension + ` ` + fourthDimension + `
</tr>
</table>
`

    return dimensions
  }
  returnExistingRepair(defect) {
    if (defect.existingRepair) {
      return `
<td><label>Existing repair:</label><span class="glyphicon glyphicon-ok"></span></td>
           <td><label>Comment:</label><span>`+ this.checkNulls(defect.existingRepairNotes) + `</span></td>`

    } else {
      return `
<td><label>Existing repair:</label><span class="glyphicon glyphicon-remove"></span></td>
            <td><label>Comment:</label><span>`+ this.checkNulls(defect.existingRepairNotes) + `</span></td>`
    }


  }
  returnInternalStr(defect) {
    if (defect.intStrAffected) {
      return `
<td><label>Internal structure affected:</label><span class="glyphicon glyphicon-ok"></span></td>
           <td><label>Comment:</label><span>`+ this.checkNulls(defect.internalStructureNotes) + `</span></td>`

    } else {
      return `
<td><label>Internal structure affected:</label><span class="glyphicon glyphicon-remove"></span></td>
            <td><label>Comment:</label><span>`+ this.checkNulls(defect.internalStructureNotes) + `</span></td>`
    }
  }
  createPartTempl(nri) {

    let part = `
<div class="part-template">
        <h3>Part Information:</h3>
    <table>
        <tr>
            <td>
                <label>Fin:</label> <span>`+ nri.part.fin + `</span>
            </td>
            <td> <label>Name:</label> <span>`+ nri.part.name + `</span></td>
            <td> <label>Number:</label> <span>`+ nri.part.number + `</span></td>
        </tr>
        <tr>
            <td> <label>UC:</label> <span>`+ this.checkNulls(nri.part.uc) + `</span></td>
            <td> <label>Manufacturer:</label> <span>`+ nri.part.manufacturer + `</span></td>
            <td> <label>Circuit:</label> <span>`+ nri.part.circuit + `</span></td>
        </tr>
        <tr>
            <td colspan="3"> <label>Quantity:</label> <span>`+ nri.quantity + `</span></td>
        </tr>
    </table>
</div>
`
    return part

  }
  createDefPartTempl(defect) {
    let part = ``
    if (defect.part && defect.part.id != 0) {
      part = `
<div class="def-part-template">
        <label class="underlined">Affected component:</label>
    <table>
        <tr>
            <td>
                <label>Fin:</label> <span>`+ defect.part.fin + `</span>
            </td>
            <td> <label>Name:</label> <span>`+ defect.part.name + `</span></td>
            <td> <label>Number:</label> <span>`+ defect.part.number + `</span></td>
        </tr>
        <tr>
            <td> <label>UC:</label> <span>`+ this.checkNulls(defect.part.uc) + `</span></td>
            <td> <label>Manufacturer:</label> <span>`+ defect.part.manufacturer + `</span></td>
            <td> <label>Circuit:</label> <span>`+ defect.part.circuit + `</span></td>
        </tr>
        <tr>
            <td colspan="3"> <label>Quantity:</label> <span>`+ defect.quantity + `</span></td>
        </tr>
    </table>
</div>
`
    }

    return part

  }
  createDefATATempl(defect) {
    let ata = `
<div class="ata-templte">
   <h3>ATA Information:</h3>
    <table>
        <tr>
            <td><label>Chapter:</label><span>`+ this.checkNulls(defect[0].ataChapter.chapter) + `</span></td>
            <td><label>Section:</label><span>`+ this.checkNulls(defect[0].ataChapter.section) + `</span></td>
        </tr>
    </table>
</div>
`
    return ata
  }

  positionsTemplate(defect) {
    let templ=``;
    if (defect[0].firstPosition) {
      templ = `<span class='block'><label>First position:</label> ` + this.checkNulls(defect[0].firstPosition) + ` </span>`
    }
    if (defect[0].secondPosition) {
      templ = `<span class='block'><label>Second position:</label> ` + this.checkNulls(defect[0].secondPosition) + `</span>`
    }
    if (defect[0].firstPosition && defect[0].secondPosition) {
      templ = `<span class='block'><label>First position:</label> ` + this.checkNulls(defect[0].firstPosition) + ` </span><span class='block'><label>Second position:</label>` + this.checkNulls(defect[0].secondPosition) + `</span>`
    }
    return templ
  }

  skecthTempl(defect) {
    if (defect[0].sketch && defect[0].sketch.id != 0) {
      return `<div><h3>Sketch Info</h3><label>` + defect[0].sketch.name + `</label></div>` + this.positionsTemplate(defect)
    } else {
      return ``
    }
  }

  addGeneralPosition(defect) {
    let templ=``;

    templ = `    
      <table>
      <tr>
      <td><label>From</label> <span>` + this.checkNulls(defect.fromMemberFirst) + ` </span></td>
      <td><label>To</label> <span>` + this.checkNulls(defect.toMemberFirst) + ` </span></td>
      <td><label>Value</label> <span>`+ this.checkNulls(defect.valueFirst) + ` </span> mm from ` + this.checkNulls(defect.closestFirst) + `</td>
      </tr>
      <tr>
      <td><label>From</label> <span>` + this.checkNulls(defect.fromMemberSecond) + ` </span></td>
      <td><label>To</label> <span>` + this.checkNulls(defect.toMemberSecond) + ` </span></td>
      <td><label>Value</label> <span>`+ this.checkNulls(defect.valueSecond) + ` </span> mm from ` + this.checkNulls(defect.closestSecond) + `</td>
      </tr>
      </table>

  <table>
        <tr>
            <td><label>From Edge:</label> <span>`+ this.checkNulls(defect.fromEdge) + `</span></td>
            <td><label>To Edge:</label> <span>`+ this.checkNulls(defect.toEdge) + `</span></td>
        </tr>
    </table>
`
    return templ;

  }
  wingPosition(defect) {
    let templ=``;

    templ = `    
        <table>
        <tr>
            <td><label>From</label> `+ defect.sketch.firstMember.name + ` <span>` + this.checkNulls(defect.fromMemberFirst) + `</span></td>
            <td><label>To</label> `+ defect.sketch.firstMember.name + ` <span>` + this.checkNulls(defect.toMemberFirst) + `</span></td>
            <td><label>Value</label> <span>`+ this.checkNulls(defect.valueFirst) + `</span> mm from ` + defect.sketch.firstMember.name + ` ` + this.checkNulls(defect.closestFirst) + `</td>
        </tr>
        <tr>
            <td><label>From</label> `+ defect.secondMemberType + ` <span>` + this.checkNulls(defect.fromMemberSecond) + `</span></td>
            <td><label>To</label> `+ defect.secondMemberType + ` <span>` + this.checkNulls(defect.toMemberSecond) + `</span></td>
            <td><label>Value</label> <span>`+ this.checkNulls(defect.valueSecond) + `</span> mm from ` + defect.secondMemberType + ` ` + this.checkNulls(defect.closestSecond) + `</td>
        </tr>
    </table>
`
    return templ;

  }
  checkNulls(val) {
    if (val) {
      if (val.name) {
        return val.name

      } else {
        return val
      }
    } else {
      return " - "
    }
  }
  inletCowPosition(defect) {
    let templ=``;
    if (defect.sketch && defect.sketch.name == "Inlet cowl") {
      templ = `
   <table>
        <tr>
            <td><label>Value:</label> <span>`+ this.checkNulls(defect.valueFirst) + `</span> mm from Clock orientation</td>
            <td><label>Value:</label> <span>`+ this.checkNulls(defect.valueSecond) + `</span> mm from ` + this.checkNulls(defect.closestSecond) + `</td>

        </tr>

    </table>
`
      return templ;
    }

  }
  typeTemplate(defect) {
    let typeTempl = ``
    if (defect.mainType && defect.mainType != "") {
      typeTempl = typeTempl + `
<span><span>`+ defect.mainType + ` - </span></span>
`
    }
    if (defect.defectType && defect.defectType.id != 0) {
      typeTempl = typeTempl + `
<span><span>`+ defect.defectType.name + `</span></span>
`
    }
    if (defect.defectSubType && defect.defectSubType.id != 0) {
      typeTempl = typeTempl + `
<span><span> - `+ defect.defectSubType.name + `</span></span>
`
    }

    return typeTempl;
  }
  positioningTemplate(defect) {
    let templ = ``;

    if (defect.sketch && defect.sketch.name == "General") {
      templ = this.addGeneralPosition(defect)
    }
    if (defect.sketch && defect.sketch.name == "Wing") {
      templ = this.wingPosition(defect)
    }
    if (defect.sketch && defect.sketch.name == "Inlet cowl") {
      templ = this.inletCowPosition(defect)
    }
    if (defect.sketch.id!=0 && defect.sketch && defect.sketch.name != "General" && defect.sketch.name != "Wing" && defect.sketch.name != "Inlet cowl") {
      templ = `
    <table>
        <tr>
            <td>From `+ defect.sketch.firstMember.name + ` <span>` + this.checkNulls(defect.fromMemberFirst) + `</span></td>
            <td>To `+ defect.sketch.firstMember.name + ` <span>` + this.checkNulls(defect.toMemberFirst) + `</span></td>
            <td>Value <span>`+ this.checkNulls(defect.valueFirst) + `</span> mm from ` + defect.sketch.firstMember.name + ` ` + this.checkNulls(defect.closestFirst) + `</td>
        </tr>
        <tr>
            <td>From `+ defect.sketch.secondMember.name + ` <span>` + this.checkNulls(defect.fromMemberSecond) + `</span></td>
            <td>To `+ defect.sketch.secondMember.name + ` <span>` + this.checkNulls(defect.toMemberSecond) + `</span></td>
            <td>Value <span>`+ this.checkNulls(defect.valueSecond) + `</span> mm from ` + defect.sketch.secondMember.name + ` ` + this.checkNulls(defect.closestSecond) + `</td>
        </tr>
    </table>
`
    }
    return templ
  }
  strSpesificTempl(defect) {
    let templ = `
   <table>
        <tr>
            <td><label>Nominal: </label><span>`+ this.checkNulls(defect.nominal) + `</span>mm</td>
            <td><label>Remaining: </label><span>`+ this.checkNulls(defect.remaining) + `</span>mm</td>
            <td><label>Skin thickness: </label><span>`+ this.checkNulls(defect.skinThickness) + `</span>mm</td>
        </tr>
    </table>
`
    return templ
  }

 

}
