import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


@Component({
  selector: 'dialog-delete',
  template: `<div mat-dialog-content>
  <p>Are you sure you want to delete this item?</p>
 
</div>
<div mat-dialog-actions>
  <button class="lht-btn" mat-button (click)="onYesClick()">Yes</button>
  <button class="lht-btn cancel"  mat-button (click)="onNoClick()" >No</button>
</div>`
})
export class DialogDeleteComponent {

  constructor(public dialogRef: MatDialogRef<DialogDeleteComponent>) { }
  

  onNoClick(): void {
    this.dialogRef.close(false);
  }
  onYesClick() {
    this.dialogRef.close(true);

  }
}
