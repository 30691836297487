
import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { FormsService } from '../forms.service';
import { NonRoutineItemService } from '../nonRoutineItem.service';
@Component({
  selector: 'user-preferences',
  templateUrl: 'user-preferences.html'
})

export class UserPreferencesComponent implements OnInit {
  currUserSubscr: Subscription;
  changeUserMetricSubscr: Subscription;
  currUser;
  metric = [{ id: 1, name: 'Millimeters', value: true }, { id: 2, name: 'Inches', value: false }]
  metricChosen;
  alertMessage: string = "You have successfully changed user metric system!"
  showNotification: boolean = false;
  isSuccess: boolean;
  userMetric;
  closeAlert(message) {
    this.showNotification = false;
  }
  constructor(private fservice: FormsService, private apiService: NonRoutineItemService) { }
  metricVal(message) {
    this.metricChosen = message;
  }
  isMetric() {
 
    this.changeUserMetricSubscr = this.apiService.updateUserMetric(this.currUser.id, this.metricChosen.value).subscribe(
      suc => {
        this.showNotification = true;
        this.isSuccess = true;

      },
        err => {
          console.log(err);
          this.isSuccess = false;
        }
    );

  }

  ngOnInit() {
    this.currUserSubscr = this.fservice.castCurrentUser.subscribe(result => {
      if (result) {
        this.currUser = result[0];
        this.userMetric = this.metric.filter(item => item.value == result[0].metricSystem)[0];
        this.metricChosen = this.userMetric;
      
      }
    })
    
  }
  ngOnDestroy() {
    if (this.changeUserMetricSubscr) {
      this.changeUserMetricSubscr.unsubscribe();
    }
   
  }
 
}
