import {
  Component, ViewChild, ChangeDetectionStrategy ,EventEmitter, Output,Input
} from '@angular/core';
import { Observable, Subscription } from 'rxjs';

import { NonRoutineItemService } from '../nonRoutineItem.service';
import { DefectType, Defect, Sketch, Edge, Notification } from '../models';
import { FormsService } from '../forms.service';
import { WhatWherePresenter } from './what-where.presenter';
import { WhatWhereComponent } from './what-where.component';
import { DomSanitizer } from '@angular/platform-browser';
import { FormGroup, FormControl, FormArray, FormBuilder } from '@angular/forms';
/**
 * @title Basic select
 */
@Component({
  selector: 'what-where-container',
  templateUrl: 'what-where.container.html',
  providers: [NonRoutineItemService, WhatWherePresenter],
 // changeDetection: ChangeDetectionStrategy.OnPush,

})

export class WhatWhereContainer {
  @ViewChild(WhatWhereComponent, { static: false }) children: WhatWhereComponent;
  @Output() isSuccess: EventEmitter<Notification> = new EventEmitter<Notification>(); 
  private isNewSubscribtion: Subscription;
  private idSubscribtion: Subscription;
  private defectIDSubscribtion: Subscription;
  private typeSubscribtion: Subscription;
  defectSubscribtion: Subscription;
  @Input() disableFormFields:boolean;
  currUser;
  currUserId: number;
  userSubscription: Subscription;
  defect;


  public typeOptions = [
    { id: 'AVI', name: 'AVI', value: 'AVI', radioGroup: 'typeOptions' },
    { id: 'SYS', name: 'SYS', value: 'SYS', radioGroup: 'typeOptions' },
    { id: 'STR', name: 'STR', value: 'STR', radioGroup: 'typeOptions' }
  ];
  public repairOptions = [
    { id: 'Yes', name: 'Yes', value: true, radioGroup: 'repairOptions' },
    { id: 'No', name: 'No', value: false, radioGroup: 'repairOptions' }

  ];;
  public internalOptions = [
    { id: 'Yes', name: 'Yes', value: true, radioGroup: 'internalOptions' },
    { id: 'No', name: 'No', value: false, radioGroup: 'internalOptions' }

  ];

  sketches: Sketch[];
  defectData$: Observable<Defect[]>;
  edgesData$: Observable<Edge[]> = this.service.getEdges();
  type: DefectType;
  prevMetricVal: boolean;
  formData: FormGroup;
  index: number = 0;
  initialDefId;
  id;
  isNew: boolean;
  defectsToSend;
  showNotification: boolean = false;

  alertMessage: string;

  constructor(
    private service: NonRoutineItemService,
    private presenter: WhatWherePresenter,
    private fservice: FormsService,
    private fb: FormBuilder) {
    this.formData = this.fb.group({
      ataObject: new FormControl(),
      partObject: new FormControl(),
      quantity: new FormControl(),
      sketchObject: new FormControl(),
      defects: this.fb.array([])
    })
  }



  get defectsListArray() {
    return <FormArray>this.formData.get('defects')
  }
  defectFields() {

    return this.fb.group({
      id: new FormControl(),
      commonTypes: new FormControl(),
      otherTypes: new FormControl(),
      defectTypeId: new FormControl(),
      defectType: new FormControl(),
      sketchId: new FormControl(),
      ataChapterId: new FormControl(),
      quantity: new FormControl(),
      fromMemberFirst: new FormControl(),
      toMemberFirst: new FormControl(),
      valueFirst: new FormControl(),
      closestFirst: new FormControl(),
      fromMemberSecond: new FormControl(),
      toMemberSecond: new FormControl(),
      valueSecond: new FormControl(),
      closestSecond: new FormControl(),
      defectDimensions: new FormControl(),
      fromExtra: new FormControl(),
      fromExtraValue: new FormControl(),
      fromEdge: new FormControl(),
      toEdge: new FormControl(),
      mainType: new FormControl(),
      showAllTypes: new FormControl(true),
      firstPosition: new FormControl(),
      secondPosition: new FormControl(),
      secondMemberType: new FormControl(),
      defectSubType: new FormControl(),
      defectSubTypes: new FormControl(),
      defectSubTypeId: new FormControl(),
      nonRoutineItemId: new FormControl(),
      fromEdgeId: new FormControl(),
      toEdgeId: new FormControl(),
      existingRepair: new FormControl(),
      internal: new FormControl(),
      intStrAffected: new FormControl(),
      nominal: new FormControl(),
      remaining: new FormControl(),
      repairProposal: new FormControl(),
      internalStructureNotes: new FormControl(),
      existingRepairNotes: new FormControl(),
      skinThickness: new FormControl(),
      part: new FormControl(),
      partId: new FormControl(),
    })
  }


  selectedType(type: string) {
    this.fservice.updateType(type);


  }

  //updateDefectViewData() {
  //  this.defectSubscribtion = this.service.getDefect(Number(this.id)).subscribe(defect => {

  //    this.defect = defect;

  //    if (this.defect) {
        
  //      console.log(this.children.sketches)

  //    }
  //  });
  //}
  updateDefectsArr(e) {

    this.defectsToSend = [];
    this.defectsListArray.controls.forEach(item => {
      let fgItem = <FormGroup>item;
      //let data:Defect = this.fservice.submitChangedData(fgItem)
      if (!this.presenter.isEmpty(fgItem)) {

        this.defectsToSend.push(<Defect>fgItem.value)


      }

    })
    if (this.defectsToSend.length !== 0 && this.formData.dirty) {
    
      this.service.updateDefects(this.defectsToSend, this.currUserId).subscribe(
        (data) => {
    
          this.defectSubscribtion = this.service.getDefect(Number(this.id)).subscribe(defect => {

     this.defect = defect;

    if (this.defect) {
      this.fservice.updateDefect(this.defect);
  

     }
   });
        
        },
        (error) => {
          this.isSuccess.emit({ message: 'Something whent wrong,defects were not updated,please try again!', isSuccess: false })
         
        }
      )

    }


  }




  handleError(error) {
 
  }
  ngOnInit() {

    this.isNewSubscribtion = this.fservice.castIsNew.subscribe(isNew => this.isNew = isNew)
    this.typeSubscribtion = this.fservice.cast.subscribe(type => this.type = type)
    this.defectIDSubscribtion = this.fservice.castInitialDefect.subscribe(id =>
      this.initialDefId = id)
    this.idSubscribtion = this.fservice.castID.subscribe(id =>
      this.id = id)


    this.userSubscription = this.fservice.castCurrentUser.subscribe(result => {

      if (result) {
        this.currUserId = result[0].id
        this.currUser = result[0].metricSystem
      }

    })
  }

  ngOnDestroy() {
    if (this.defectSubscribtion) {
      this.defectSubscribtion 
    }
    this.isNewSubscribtion.unsubscribe();
    this.idSubscribtion.unsubscribe();
    this.defectIDSubscribtion.unsubscribe();
    this.typeSubscribtion.unsubscribe();
    this.userSubscription.unsubscribe();
  }

}
