import { Router } from '@angular/router';
import { AdalService } from 'adal-angular4';
import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'app-login-component',
  template: '<div></div>'
})

export class LoginComponent implements OnInit {

  constructor(private router: Router, private adalService: AdalService) { }

  ngOnInit() {
   

    if (this.isLoggedIn) {
    
      
      let storage = window.localStorage
      let redirect: string = storage.getItem('originalUrl');
      if (redirect) {
        window.location.href = redirect;
      }
      else {
         this.router.navigate(['home']);
      }

    } else {
      this.adalService.login();
    }
  }

  public get isLoggedIn() {
    return this.adalService.userInfo.authenticated;
  }
}

