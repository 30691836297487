import { Component, Input, Output, EventEmitter } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';

/**
 * @title Configurable checkbox
 */
@Component({
  selector: 'checkbox-component',
  templateUrl: 'checkbox.component.html'

})
export class CheckboxComponent {
  @Input() types;
  @Output() checkboxVal: EventEmitter<object> = new EventEmitter<object>();
  @Input() selected;
  

  selectionChage(event: MatCheckboxChange, option,selected) {
  
    this.checkboxVal.emit({ id: option.id,checked:event.checked,name:option.name})

  }
 
}
