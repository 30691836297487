import { Component, ViewChild, Input,EventEmitter,Output} from '@angular/core';
import { NonRoutineItemService } from '../nonRoutineItem.service';
import { RectificationComponent} from '../rectification/rectification.component';
import { Observable, Subscription } from 'rxjs';
import { FormGroup, FormControl, FormArray, FormBuilder } from '@angular/forms';
import { FormsService } from '../forms.service';
import { Ticket, ActionType, RectificationAction, ReferenceType, WorkStation, Notification } from '../models';
import { WhatWherePresenter } from '../what-where-2/what-where.presenter';
import { NewTicketComponent } from '../common/dialogs/new-ticket.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HtmlTemplatesService } from './html-templates.service';
import { NewJCComponent } from '../common/dialogs/newJC.component';

@Component({
  selector: 'app-rectification-container',
  templateUrl: './rectification.container.html',
  providers: [NonRoutineItemService, WhatWherePresenter]
   
 
})
export class RectificationContainer {
  stationsData$: Observable<WorkStation[]>= this.service.getWorkStation();
  refType$: Observable<ReferenceType[]> = this.service.getRefType();
  @ViewChild(RectificationComponent, { static: false }) children: RectificationComponent;
  @Output() isSuccess: EventEmitter<Notification> = new EventEmitter<Notification>(); 
  formData: FormGroup;
  nriIdSubscr: Subscription;
  nriIdObjSubscr: Subscription;
  rectSubscr: Subscription;
  userSubscr: Subscription;
  descriptionSubscr: Subscription;
  statusSubscr: Subscription;
  nri;
  fail: boolean = false;
  priority: string="Normal";
  nriId: number;
  alertMessage: string;
  showNotification: boolean = false;
  user: string;
  selectedRefType;
  selectedRefAction;
  rectifications = [];
  rectificationsToSend;
  descriptionText: string;
  userId: number;
  defect;
  defectSubscription: Subscription
  template: string;
  @Input() disableFormFields;
  hasChanges: boolean = false;;

  constructor(private templService: HtmlTemplatesService,private service: NonRoutineItemService, public dialog: MatDialog, private fservice: FormsService, private apiService: NonRoutineItemService, private fb: FormBuilder, private presenter: WhatWherePresenter) {
    this.formData = this.fb.group({
      rectifications: this.fb.array([])
    });

  }
  get rectificationsListArray() {
    return <FormArray>this.formData.get('rectifications')
  }
  rectificationFields() {
    return this.fb.group({
      id: new FormControl(),
      actionTypeId: new FormControl(),
      referenceTypeId: new FormControl(),
      referenceDocRevision: new FormControl(),
      referenceDocNumber: new FormControl(),
      workStationId: new FormControl(),
      nonRoutineItemId: new FormControl(),
      actionType: new FormControl(),
      referenceType: new FormControl(),
      workStation: new FormControl(),
      item: new FormControl(), 
      selectedRadio: new FormControl(),
      zone: new FormControl(),
      zoneId: new FormControl(),
      panel: new FormControl(),
      wiring: new FormControl(),
      part: new FormControl(),
      partId: new FormControl(),
      quantity: new FormControl(),
      duplicateInspection: new FormControl(),
      taskId: new FormControl(),
      disableCurr: new FormControl()
    
    
    });
  }
  openJCDialog(jcId: number) {

    const dialogRef = this.dialog.open(NewJCComponent, {
      width: '350px',
      data: { id: jcId }

    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {



      }
    });
  }
  selectedVal(message) {
    if (message.what.id == 1 && message.what.checked == true) {
      message.fg.controls['part'].setValue('')
      message.fg.controls['zone'].setValue(null)
      message.fg.controls['wiring'].setValue(null)
      message.fg.controls['panel'].setValue(null)




    } else {
      message.fg.controls['part'].setValue(null)
    }

    if (message.what.id == 2 && message.what.checked == true) {
      message.fg.controls['zone'].setValue(null)
      message.fg.controls['wiring'].setValue(null)
      message.fg.controls['panel'].setValue('')


    }
    if (message.what.id == 3 && message.what.checked == true) {
      message.fg.controls['zone'].setValue('')
      message.fg.controls['wiring'].setValue(null)
      message.fg.controls['panel'].setValue(null)


    }
    if (message.what.id == 4 && message.what.checked == true) {
      message.fg.controls['zone'].setValue(null)
      message.fg.controls['wiring'].setValue('')
      message.fg.controls['panel'].setValue(null)

    }
    if (message.what.checked == false) {
      message.fg.controls['zone'].setValue(null)
      message.fg.controls['wiring'].setValue(null)
      message.fg.controls['panel'].setValue(null)
    }
  }
  createRectification(message) {
    if (message) {
      const dataToSend: RectificationAction = {
        nonRoutineItemId:this.nriId
      }
      this.apiService.createRectificationAction(dataToSend).subscribe(result => {
      
        this.rectificationsListArray.push(this.rectificationFields())
        let control = <FormGroup>this.rectificationsListArray.controls[this.rectificationsListArray.controls.length - 1]
        control.controls['id'].markAsDirty();
        control.controls['id'].setValue(result.id);
        control.controls['nonRoutineItemId'].markAsDirty();
        control.controls['nonRoutineItemId'].setValue(this.nriId);
      }, err => {
          this.isSuccess.emit({ message: 'Something whent wrong,could not create rectification!Please try again!', isSuccess: false })
        });
    }
  }
  createRectJC(e) {
    this.template = ``;
    this.template = `
<div class="sketch-pos">

`+ this.templService.skecthTempl(this.defect)+ `
</div>
`

    if (this.nri.part && this.nri.part.id != 0) {
      this.template = this.template + this.templService.createPartTempl(this.nri)
    }
    if (this.defect[0].ataChapter && this.defect[0].ataChapter.id && this.defect[0].ataChapter.id != 0) {
      this.template = this.template + this.templService.createDefATATempl(this.defect)
    }

    this.defect.forEach((value, index) => {
      this.template = this.template +`<div class="defect-template">`+ this.templService.createDefTempl(value, index + 1)+`</div>`
     
    });
  
   

    if (this.formData.dirty) {
      this.children.form.ngSubmit.emit(this.formData);
      this.apiService.createRectJC(this.nriId, this.template.toString()).subscribe(result => {
        this.fail = false;
        this.updateRectificationData();
        this.openJCDialog(Number(result))
       
      }, err => {
          this.fail = true;
          setTimeout(() => {
            this.fail = false;

          }, 3000);
        })
    } else {
      this.apiService.createRectJC(this.nriId, this.template.toString()).subscribe(result => {
        this.fail = false;
        this.updateRectificationData();
        this.openJCDialog(Number(result))
      }, err => {
          this.fail = true;
          setTimeout(() => {
            this.fail = false;

          }, 3000);
      })
    }
   
  }



  updateRectifications(message: any) {
    this.rectificationsToSend = [];
    this.rectificationsListArray.controls.forEach(item => {
      let fgItem = <FormGroup>item;
   
     // let data: RectificationAction = this.fservice.submitChangedData(fgItem)
      if (!this.presenter.isEmpty(fgItem) && fgItem.dirty) {

        this.rectificationsToSend.push(<RectificationAction>fgItem.value)


      }

    })
  
    if (this.rectificationsToSend.length !== 0 && this.formData.dirty) {
      this.apiService.updateRectificationAction(this.rectificationsToSend, this.userId).subscribe(result => {
        this.statusSubscr = this.apiService.updateNRIStatus(this.nriId, "Created Job Card").subscribe(data => {
          this.isSuccess.emit({ message: 'Successfully updated rectification!', isSuccess: true })
        }, err => {
          this.isSuccess.emit({ message: 'Something whent wrong,please try again!', isSuccess: false })
        })
       
        this.formData.markAsPristine();
        if (message.description) {
          this.descriptionSubscr = this.service.updateNRIDescription(this.nriId, message.description).subscribe(result => { 
        })
        }  
      }, err => {
        this.isSuccess.emit({ message: 'Something whent wrong,please try again!', isSuccess: false })
      });
    }

   
  }


  //createTicket(message) {
  //   if (message) {
  //  const dialogRef = this.dialog.open(NewTicketComponent, {
  //    width: '350px',
  //    data: { id:2,name: "Normal"}
  //  });
  //  dialogRef.afterClosed().subscribe(result => {
  //    if (result) {
  //      this.priority = result;
  //            const ticket: Ticket = {
  //      NonRoutineItemId: this.nriId,
  //      CreatedBy: this.user,
  //      ModifiedBy: this.user,
  //      Priority: this.priority,
  //      StatusId:1
  //      }
       
      
  //    this.apiService.createTicket(ticket).subscribe(result => {
  //            this.statusSubscr = this.apiService.updateNRIStatus(this.nriId, "Raised Ticket").subscribe(data => {
  //        this.isSuccess.emit({ message: 'Successfully created ticket to AE for this Non Routine Item!', isSuccess: true })
  //      }, err => {
     
  //          this.isSuccess.emit({ message: 'Something whent wrong,please try again!', isSuccess: false })
  //        })

  //    }, err => {
       
  //        this.isSuccess.emit({ message: 'Something whent wrong,please try again!', isSuccess: false })
  //      })
  //    }
  //  });
  // }

    
  
    
  //}
  
  updateRectificationData() {
    this.rectificationsListArray.clear();
 this.rectSubscr = this.apiService.getRectifications(this.nriId).subscribe(result => {
          if (result) {

            this.rectifications = result;

            for (let i = 0; i < this.rectifications.length; i++) {
              this.rectificationsListArray.push(this.rectificationFields())

              this.rectificationsListArray.controls[i].patchValue(this.rectifications[i])

            }
            this.hasChanges = false
            this.rectificationsListArray.controls.forEach((item, index) => {

              let currControl = <FormGroup>item

              if (currControl.controls['actionType'].value) {
                currControl.controls['actionTypeId'].setValue(currControl.controls['actionType'].value.id)
              }
              if (currControl.controls['referenceType'].value) {
                currControl.controls['referenceTypeId'].setValue(currControl.controls['referenceType'].value.id)
              }
              if (currControl.controls['workStation'].value) {
                currControl.controls['workStationId'].setValue(currControl.controls['workStation'].value.id)
              }
              if (currControl.controls['part'].value) {
                currControl.controls['selectedRadio'].setValue('Component')
                currControl.controls['partId'].setValue(currControl.controls['part'].value.id)
              }
              if (currControl.controls['zone'].value) {
                currControl.controls['selectedRadio'].setValue('Zone')
                currControl.controls['zoneId'].setValue(currControl.controls['zone'].value.id)
              }

              if (currControl.controls['panel'].value) {
                currControl.controls['selectedRadio'].setValue('Panel')

              }
              if (currControl.controls['wiring'].value) {
                currControl.controls['selectedRadio'].setValue('Wiring')
              }
              if (currControl.controls['taskId'].value) {
                currControl.controls['disableCurr'].setValue(true)
              } else {
                this.hasChanges = true
              }

            })


          }
        })


  }
  ngOnInit() {

    
    this.nriIdObjSubscr = this.fservice.castNriObject.subscribe(result => {
      if (result) {

        this.nri = result[0];
        this.descriptionText = result[0].descriptionText


        this.nriId = Number(result[0].id)
        this.updateRectificationData();




      }

    });

    this.userSubscr = this.fservice.castCurrentUser.subscribe(result => {
      
      if (result) {
        this.user = result[0].name
        this.userId =Number(result[0].id)
      }
     
    })
    this.defectSubscription = this.fservice.castDefect.subscribe(def => {
      if (def && def.length > 0) {
        this.defect = def
       


      }

    });
  }
  ngOnDestroy() {
    if (this.defectSubscription) {
      this.defectSubscription.unsubscribe();
    }

    if (this.rectSubscr) {
      this.rectSubscr.unsubscribe();
    }
    if (this.statusSubscr) {
      this.statusSubscr.unsubscribe();
    }
    if (this.descriptionSubscr) {
      this.descriptionSubscr.unsubscribe();
    }
    this.userSubscr.unsubscribe();
    this.nriIdObjSubscr.unsubscribe();
  }
}
