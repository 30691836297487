import { FormGroup } from '@angular/forms';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { DefectType, User, NonRoutineItem ,WorkStation} from './models';

@Injectable()

export class FormsService {
  private workStations = new BehaviorSubject<WorkStation[]>([]);
  castworkStations = this.workStations.asObservable();

  private currentUser = new BehaviorSubject<any>(null);
  castCurrentUser = this.currentUser.asObservable();

  private owner = new BehaviorSubject<User>(null);
  castOwner = this.owner.asObservable();

  private chapter = new BehaviorSubject<string>(null);
  castChapter = this.chapter.asObservable();

  private isNew = new BehaviorSubject<boolean>(false);
  castIsNew = this.isNew.asObservable();

  private partName = new BehaviorSubject<string>('');
  castPartName = this.partName.asObservable();

  private defect = new BehaviorSubject<[]>([]);
  castDefect = this.defect.asObservable();

  private stepsArray = new BehaviorSubject<[]>([]);
  castStepsArray = this.stepsArray.asObservable();

  private initialDefectID = new BehaviorSubject<number>(null);
  castInitialDefect = this.initialDefectID.asObservable();

  private defPanelArray = new BehaviorSubject<[]>([]);
  castDefPanelArray = this.defPanelArray.asObservable();

  private involvedWC = new BehaviorSubject<object>(null);
  castInvolvedWC = this.involvedWC.asObservable();

  private nriObject = new BehaviorSubject<NonRoutineItem>(null);
  castNriObject = this.nriObject.asObservable();

  private nriID = new BehaviorSubject<number>(null);
  castID = this.nriID.asObservable();

  private type = new BehaviorSubject<DefectType>(null);
  cast = this.type.asObservable();

  updateWorkStations(stations) {
    this.workStations.next(stations)
  }

  updateUser(currUser) {
    this.currentUser.next(currUser)
  }
  updateNriObject(nriObject) {
    this.nriObject.next(nriObject)
  }
  updateOwner(owner) {
    this.owner.next(owner)
  }

  updateChapter(chapter) {
    this.chapter.next(chapter)
  }

  updatePartName(newName) {
    this.partName.next(newName)
  }

  updateIsNew(newVal) {
    
    this.isNew.next(newVal)
  }

  updateDefect(newDefect) {
    this.defect.next(newDefect)
  }

  updateDefPanel(newPanelArr) {
    this.defPanelArray.next(newPanelArr)
  }

  updateStepsArray(newStepsArr) {
    this.stepsArray.next(newStepsArr)
  }

  //udate defectID
  updateDefectID(newID) {
    this.initialDefectID.next(newID)
  }

  //udate type when changed
  updateType(newType) {
    this.type.next(newType)
  }
  updateInvolvedWC(newSelection) {
    this.involvedWC.next(newSelection)
  }

  updateNewID(id) {
    this.nriID.next(id)
  }
  //get only changed data from forms
  submitChangedData(data: FormGroup) {
    const dirtyValues = {};
    Object.keys(data.controls).forEach(c => {
      const currentControl = data.get(c);

      if (currentControl.dirty) {
        dirtyValues[c] = currentControl.value;
      }
    });

    return dirtyValues;
  }

 

}
