import { DefectType, Parts } from '../models';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { ActivatedRoute } from "@angular/router";
export class WhatWherePresenter {
  constructor(private route: ActivatedRoute) { }
  id: number;
  filterCommonTypes(arr: DefectType[], selectedType: string) {
    let filteredByType = arr.filter(item => item[selectedType])
    return filteredByType.filter(item => item['isCommon']);
  }

  filterOtherTypes(arr: DefectType[], selectedType: string) {
    let filteredByType = arr.filter(item => item[selectedType])
    return filteredByType.filter(item => !item['isCommon']);
  }

  getItemId() {
    this.route.params.subscribe(params => this.id = params.id);
    return this.id;
  }
  isEmpty(obj) {
    for (var key in obj) {
      if (obj.hasOwnProperty(key))
        return false;
    }
    return true;
  }
  filterById(arr, id) {
  
    return arr.filter(item => item.id.includes(id))

  }
  convertMmToInches(mm) {
    return mm / 25.4

  }
  convertInchesToMm(inches) {
    return inches * 25.4
  }
}
