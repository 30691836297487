import { ActivatedRoute } from "@angular/router";
import { Observable, Subscription, from } from 'rxjs';
import { NonRoutineItemOverview, WorkStation, NonRoutineItem } from '../models';
import { map } from 'rxjs/operators';
export class OverviewPresenter{
  constructor(private route: ActivatedRoute) {}
  id: number;
  item;
  getItemId() {
    this.route.params.subscribe(params => this.id = params.id);
    return this.id;
  }
   isEmpty(obj) {
  for (var key in obj) {
    if (obj.hasOwnProperty(key))
      return false;
  }
  return true;
  }
  filterById(arr) {
   
    let idArr:any = [];
    arr.forEach(item => {
      idArr.push(item.id)
    })

    return idArr;

  }

}
